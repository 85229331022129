import * as React from 'react';
export var Logo = (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 737 68" },
    React.createElement("defs", null,
        React.createElement("clipPath", { id: "dde08c3a70" },
            React.createElement("path", { d: "M 365.273438 216.261719 L 304.5625 216.261719 L 291.976562 216.273438 L 304.5625 228.699219 L 353.226562 228.699219 L 353.226562 265.15625 C 353.226562 268.78125 350.289062 271.707031 346.675781 271.707031 L 328.496094 271.707031 L 328.496094 232.113281 L 304.5625 232.113281 L 304.5625 280.929688 L 365.273438 280.929688 C 376.46875 280.929688 383.722656 273.675781 383.722656 262.378906 L 383.722656 234.710938 C 383.722656 224.523438 375.460938 216.261719 365.273438 216.261719 ", "clip-rule": "nonzero" })),
        React.createElement("clipPath", { id: "6cf2321d74" },
            React.createElement("path", { d: "M 449.515625 265.457031 L 468.402344 265.457031 L 468.34375 280.929688 L 427.929688 280.929688 L 428.417969 215.816406 L 449.496094 215.816406 Z M 449.515625 265.457031 ", "clip-rule": "nonzero" }))),
    React.createElement("g", { "clip-path": "url(#2ad529aa4d)", transform: "matrix(1, 0, 0, 1, -41.064011, -214.621353)" },
        React.createElement("g", { transform: "matrix(1, 0, 0, 1, 36, 210)" },
            React.createElement("path", { fill: "#0033a0", d: "M 23.273438 40.644531 C 31.695312 30.714844 44.160156 24.328125 58.085938 23.960938 L 105.234375 70.835938 L 137.585938 70.835938 L 72.433594 6.046875 L 71.140625 6.046875 C 35.726562 6.046875 6.785156 34.1875 6.007812 69.390625 L 5.976562 70.835938 L 12.160156 70.835938 L 12.230469 68.945312 C 12.859375 57.335938 22.53125 48.242188 34.226562 48.242188 L 78.125 48.242188 L 66.019531 36.21875 L 40.128906 36.21875 C 34.007812 36.21875 28.253906 37.820312 23.273438 40.644531 ", "fill-opacity": "1", "fill-rule": "evenodd" }),
            React.createElement("path", { fill: "#0033a0", d: "M 196.320312 36.207031 L 143.800781 36.207031 L 143.800781 23.960938 L 196.320312 23.960938 C 199.722656 23.960938 202.484375 26.707031 202.484375 30.089844 C 202.484375 33.472656 199.722656 36.21875 196.320312 36.21875 Z M 202.492188 48.234375 C 214.207031 48.222656 223.699219 38.785156 223.699219 27.136719 C 223.699219 15.488281 214.207031 6.046875 202.484375 6.046875 L 129.015625 6.046875 L 129.015625 57.84375 L 142.070312 70.835938 L 153.703125 70.835938 L 153.703125 48.304688 L 170.140625 48.242188 L 192.859375 70.835938 L 225.210938 70.835938 Z M 202.492188 48.234375 ", "fill-opacity": "1", "fill-rule": "nonzero" }),
            React.createElement("path", { fill: "#0033a0", d: "M 102.402344 6.046875 L 123.792969 6.046875 L 123.792969 52.628906 L 102.402344 31.414062 Z M 102.402344 6.046875 ", "fill-opacity": "1", "fill-rule": "evenodd" }),
            React.createElement("path", { fill: "#d90012", d: "M 37.597656 5.867188 L 6.347656 5.867188 L 6.347656 16.871094 L 22.460938 16.871094 C 27.003906 12.601562 32.085938 8.902344 37.597656 5.867188 ", "fill-opacity": "1", "fill-rule": "evenodd" }),
            React.createElement("path", { fill: "#0033a0", d: "M 22.460938 16.871094 L 6.347656 16.871094 L 6.347656 27.871094 L 12.847656 27.871094 C 15.691406 23.890625 18.910156 20.210938 22.472656 16.871094 ", "fill-opacity": "1", "fill-rule": "evenodd" }),
            React.createElement("path", { fill: "#f2a800", d: "M 12.839844 27.871094 L 6.347656 27.871094 L 6.347656 38.875 C 8.195312 35.003906 10.378906 31.332031 12.847656 27.871094 ", "fill-opacity": "1", "fill-rule": "evenodd" }))),
    React.createElement("g", { "clip-path": "url(#66371a22b6)", transform: "matrix(1, 0, 0, 1, -41.064011, -214.621353)" },
        React.createElement("g", { "clip-path": "url(#dde08c3a70)", "x-used": "true" },
            React.createElement("path", { fill: "#0033a0", d: "M 291.976562 216.261719 L 383.871094 216.261719 L 383.871094 281.1875 L 291.976562 281.1875 Z M 291.976562 216.261719 ", "fill-opacity": "1", "fill-rule": "nonzero" })),
        React.createElement("g", { transform: "matrix(1, 0, 0, 1, 395, 216)" },
            React.createElement("path", { fill: "#0033a0", d: "M 0 0.0703125 L 21.726562 0.0703125 L 21.726562 65.246094 L 0 65.246094 Z M 0 0.0703125 ", "fill-opacity": "1", "fill-rule": "evenodd" })),
        React.createElement("g", { "clip-path": "url(#6cf2321d74)", "x-used": "true" },
            React.createElement("path", { fill: "#0033a0", d: "M 427.929688 215.816406 L 468.882812 215.816406 L 468.882812 280.742188 L 427.929688 280.742188 Z M 427.929688 215.816406 ", "fill-opacity": "1", "fill-rule": "nonzero" })),
        React.createElement("g", { transform: "matrix(1, 0, 0, 1, 480, 216)" },
            React.createElement("path", { fill: "#0033a0", d: "M 0.277344 0.0703125 L 22 0.0703125 L 22 65.246094 L 0.277344 65.246094 Z M 0.277344 0.0703125 ", "fill-opacity": "1", "fill-rule": "evenodd" })),
        React.createElement("g", { transform: "matrix(1, 0, 0, 1, 508, 211)" },
            React.createElement("g", { transform: "matrix(1, 0, 0, 1, 0.000000000000056843, 0)" },
                React.createElement("path", { fill: "#0033a0", d: "M 151.738281 35.796875 L 151.738281 44.167969 L 139.316406 31.808594 L 139.316406 5.058594 L 153.4375 5.058594 L 218.539062 69.976562 L 186.023438 69.976562 Z M 151.738281 35.796875 ", "fill-opacity": "1", "fill-rule": "evenodd" }))),
        React.createElement("g", { transform: "matrix(1, 0, 0, 1, 508, 211)" },
            React.createElement("g", { transform: "matrix(1, 0, 0, 1, 0.000000000000056843, 0)" },
                React.createElement("path", { fill: "#0033a0", d: "M 217.917969 65.210938 L 217.917969 5.335938 L 196.730469 5.335938 L 196.730469 44.070312 Z M 217.917969 65.210938 ", "fill-opacity": "1", "fill-rule": "evenodd" }),
                React.createElement("path", { fill: "#0033a0", d: "M 59.25 39.722656 C 67.667969 29.777344 80.132812 23.378906 94.035156 23.007812 L 141.132812 69.976562 L 173.460938 69.976562 L 108.367188 5.058594 L 107.089844 5.058594 C 71.707031 5.058594 42.777344 33.253906 42.007812 68.53125 L 41.980469 69.964844 L 48.15625 69.964844 L 48.238281 68.070312 C 48.867188 56.441406 58.53125 47.328125 70.207031 47.328125 L 83.570312 47.328125 L 83.570312 35.289062 L 76.09375 35.289062 C 69.976562 35.289062 64.230469 36.894531 59.25 39.722656 ", "fill-opacity": "1", "fill-rule": "evenodd" }),
                React.createElement("path", { fill: "#0033a0", d: "M 86.9375 35.269531 L 102.230469 35.269531 L 114.316406 47.320312 L 86.9375 47.320312 Z M 86.9375 35.269531 ", "fill-opacity": "1", "fill-rule": "evenodd" }),
                React.createElement("path", { fill: "#0033a0", d: "M 5.425781 69.785156 L 5.394531 54.40625 L 17.171875 54.40625 L 17.199219 5.066406 L 37.410156 5.066406 L 37.410156 61.742188 C 37.410156 66.457031 34.074219 69.785156 29.34375 69.785156 Z M 5.425781 69.785156 ", "fill-opacity": "1", "fill-rule": "nonzero" }),
                React.createElement("path", { fill: "#0033a0", d: "M 244.628906 20.007812 L 232.933594 8.167969 C 234.601562 6.191406 237 5.316406 240.578125 4.996094 L 264.546875 4.996094 L 264.519531 20.199219 Z M 244.628906 20.007812 ", "fill-opacity": "1", "fill-rule": "evenodd" }),
                React.createElement("path", { fill: "#0033a0", d: "M 225.9375 30.683594 C 221.316406 24.8125 222.777344 15.113281 230.742188 9.640625 L 264.597656 43.769531 C 274.082031 53.210938 265.648438 70.695312 251.683594 70.066406 L 223.285156 70.066406 L 223.308594 54.546875 L 249.003906 54.335938 Z M 225.9375 30.683594 ", "fill-opacity": "1", "fill-rule": "evenodd" }))))));
