import { __awaiter } from "tslib";
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getAeroexpress, getCustomer, getOrder, getSegments, getTravellers } from '../../../Checkout/store/order/selectors';
import { getPassengerStateCompatible } from '../../../Checkout/store/selectedServices/selectors';
import { getPassengerLabels } from '../../../Checkout/store/passengers/selectors';
import { getServiceDialogType } from './utils';
import { isServicesSaving } from '../../../Checkout/store/servicesIsSaving/selectors';
import { useAnimalService, useOrderService } from '../../../CheckoutAdditionalService/components/hooks';
import { useSmsNotifications } from '../../../CheckoutAdditionalService/components/SmsNotifications/SmsNotifications.hook';
import { isVipService } from '../../utils';
import { getAllSegmentsServicePrice } from '../../../utils';
export function useCardExtraModal({ type, gdsServiceType, openServiceType, additionalServices, isCanModifyServices, onSaveServices, onClose }) {
    var _a, _b, _c, _d, _e, _f;
    const { t, i18n } = useTranslation('Checkout');
    const order = useSelector(getOrder);
    const segments = useSelector(getSegments);
    const aeroexpress = useSelector(getAeroexpress);
    const travellers = useSelector(getTravellers);
    const passengers = useSelector(getPassengerStateCompatible);
    const passengerLabels = useSelector(getPassengerLabels);
    const isOrderServicesSaving = useSelector(isServicesSaving);
    const customer = useSelector(getCustomer);
    const openServiceDialogType = useMemo(() => getServiceDialogType(openServiceType), [openServiceType]);
    const serviceDialogModel = useMemo(() => {
        return {
            order,
            passengers,
            passengerLabels,
            openedModal: openServiceDialogType,
            onClose
        };
    }, [order, passengers, passengerLabels, openServiceDialogType, onClose]);
    const onActionClose = useCallback((request) => (...args) => __awaiter(this, void 0, void 0, function* () {
        request(...args);
        onClose();
    }), [onClose]);
    let servicePopupModel = null;
    if (type === 'animal') {
        const baggageServices = additionalServices.gdsServices.services;
        const model = useAnimalService(baggageServices, travellers);
        const petPopupProps = {
            segments,
            travellers,
            services: model.servicesByRfisc,
            isLoading: isOrderServicesSaving,
            readOnly: !isCanModifyServices,
            onConfirm: onActionClose(model.onSave),
            onClear: model.onClear,
            onClose
        };
        servicePopupModel = Object.assign({}, petPopupProps);
    }
    else if (type === 'transfer') {
        const aeroexpressPopupProps = {
            trips: aeroexpress.trips,
            selectedTrips: aeroexpress.selectedTrips,
            onSubmit: onSaveServices,
            onClose
        };
        servicePopupModel = Object.assign({}, aeroexpressPopupProps);
    }
    else if (type === 'insurance') {
        const insurancePopupProps = {
            order,
            description: (_c = (_b = (_a = additionalServices.insurances) === null || _a === void 0 ? void 0 : _a.insurancePrograms) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.description,
            insurances: (_d = additionalServices.insurances) === null || _d === void 0 ? void 0 : _d.insurancePrograms,
            selectedInsurances: (_e = additionalServices.insurances) === null || _e === void 0 ? void 0 : _e.selectedInsurances,
            onSave: onSaveServices,
            onClose
        };
        servicePopupModel = Object.assign({}, insurancePopupProps);
    }
    else if (type === 'sms') {
        const smsServices = additionalServices.gdsServices
            .services;
        const { onSubmit } = useSmsNotifications(smsServices, order.id, onClose);
        const smsPopupProps = {
            smsNotificationId: smsServices[0].id,
            title: i18n.exists('Checkout:SmsPopup_title') ? i18n.t('Checkout:SmsPopup_title') : smsServices[0].name,
            description: i18n.exists('Checkout:SmsPopup_description')
                ? i18n.t('Checkout:SmsPopup_description')
                : smsServices[0].description,
            travellers: travellers.filter(traveller => traveller.type !== 'INF'),
            customer,
            price: getAllSegmentsServicePrice(smsServices[0]),
            onClose,
            onSubmit
        };
        servicePopupModel = Object.assign({}, smsPopupProps);
    }
    else if (isVipService(type)) {
        const vipServices = additionalServices.gdsServices.services;
        const model = useOrderService(vipServices, gdsServiceType);
        const headerTitle = {
            'business-lounge': t('Business lounge'),
            'priority-boarding': t('Priority boarding'),
            'baggage-delivery': t('Baggage delivery'),
            'capsule-hotel': t('Capsule hotel')
        };
        const controlType = {
            'business-lounge': 'checkbox',
            'priority-boarding': 'checkbox',
            'baggage-delivery': 'counter',
            'capsule-hotel': 'checkbox'
        };
        const isCustomControl = type === 'business-lounge' || type === 'priority-boarding' || type === 'capsule-hotel';
        const showTotalPrice = type !== 'baggage-delivery';
        const showFromPrice = type !== 'baggage-delivery';
        const vipServiceSelectProps = {
            segments,
            passengers,
            header: (_f = headerTitle[type]) !== null && _f !== void 0 ? _f : '',
            services: additionalServices.gdsServices.services,
            selectedServices: model.selectedServices,
            subheader: model.renderSubheader(),
            renderPassenger: isCustomControl ? model.renderPassenger : undefined,
            type: controlType[type],
            showTotalPrice,
            showFromPrice,
            readOnly: !isCanModifyServices,
            onSave: onActionClose(model.onSaveHandler),
            onClose
        };
        servicePopupModel = Object.assign({}, vipServiceSelectProps);
    }
    const isFullscreenDialog = useMemo(() => {
        return type === 'seats' || type === 'baggage' || type === 'meal';
    }, [type]);
    const maxWidth = useMemo(() => {
        if (type === 'animal' ||
            type === 'business-lounge' ||
            type === 'priority-boarding' ||
            type === 'baggage-delivery' ||
            type === 'loyalty') {
            return 'sm';
        }
        else if (type === 'insurance' || type === 'capsule-hotel' || type === 'sms') {
            return 'md';
        }
        return false;
    }, [type]);
    return {
        // data
        serviceDialogModel,
        servicePopupModel,
        isFullscreenDialog,
        maxWidth
    };
}
