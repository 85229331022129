import { __rest } from "tslib";
import * as React from 'react';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import CartItemOptionAdapter from '../../../Cart/CartItemOptionAdapter/CartItemOptionAdapter';
import ServiceSummaryDivider from '../../../ServiceSummary/components/ServiceSummaryDivider/ServiceSummaryDivider';
import { useConfig } from '../../../context';
import { getMilesForOrder } from '../../../Checkout/store/order/selectors';
import { useCardExtraSummary } from './cardExtraSummary.hook';
import { isVipService } from '../../utils';
const CardExtraSummary = (_a) => {
    var _b, _c, _d, _e;
    var { className, children } = _a, props = __rest(_a, ["className", "children"]);
    const { t } = useTranslation('CardExtra');
    const { global: { companyInfo: { loyaltyProgramName } } } = useConfig();
    const milesBonus = useSelector(getMilesForOrder);
    const model = useCardExtraSummary(props);
    const content = useMemo(() => {
        var _a;
        if (props.type === 'loyalty' && ((_a = props.userInfo) === null || _a === void 0 ? void 0 : _a.userFfpInfo)) {
            return (React.createElement(React.Fragment, null, milesBonus > 0 ? (React.createElement(React.Fragment, null,
                React.createElement("span", null, t('MilesAwarded', {
                    number: milesBonus,
                    miles: t('Miles', { count: milesBonus })
                })),
                React.createElement("span", null, t('MilesWillBeCredited')))) : (React.createElement("span", null, t('MilesNotCredited')))));
        }
        if (!model.items.groups.length && !model.items.allSegmentsGroups.length) {
            return null;
        }
        return (React.createElement(React.Fragment, null,
            !!model.items.allSegmentsGroups.length && (React.createElement(ServiceSummaryDivider, null, model.items.allSegmentsGroups.map((group, index) => (React.createElement(CartItemOptionAdapter, { option: group, key: index }))))),
            !!model.items.groups.length && (React.createElement(ServiceSummaryDivider, null, model.items.groups.map((group, index) => (React.createElement(CartItemOptionAdapter, { option: group, key: index })))))));
    }, [props.type, model.items]);
    const isSelected = useMemo(() => {
        var _a;
        if (props.type === 'loyalty') {
            return !!((_a = props.userInfo) === null || _a === void 0 ? void 0 : _a.userFfpInfo);
        }
        return !!content;
    }, [props.type, props.userInfo, content]);
    const title = useMemo(() => {
        var _a, _b, _c, _d, _e;
        if (props.type === 'loyalty') {
            return loyaltyProgramName;
        }
        else if (props.type === 'insurance' && ((_b = (_a = props.additionalServices.insurances) === null || _a === void 0 ? void 0 : _a.insurancePrograms) === null || _b === void 0 ? void 0 : _b.length) === 1) {
            return (_e = (_d = (_c = props.additionalServices.insurances) === null || _c === void 0 ? void 0 : _c.insurancePrograms) === null || _d === void 0 ? void 0 : _d[0]) === null || _e === void 0 ? void 0 : _e.name;
        }
        return null;
    }, [
        props.type,
        props.additionalServices,
        (_b = model.items.groups[0]) === null || _b === void 0 ? void 0 : _b.items,
        (_c = model.items.allSegmentsGroups[0]) === null || _c === void 0 ? void 0 : _c.items,
        loyaltyProgramName
    ]);
    const description = useMemo(() => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
        if (!isSelected) {
            if (props.type === 'insurance') {
                return (_c = (_b = (_a = props.additionalServices.insurances) === null || _a === void 0 ? void 0 : _a.insurancePrograms) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.description;
            }
            else if (isVipService(props.type)) {
                return (_f = (_e = (_d = props.additionalServices.gdsServices) === null || _d === void 0 ? void 0 : _d.services) === null || _e === void 0 ? void 0 : _e[0]) === null || _f === void 0 ? void 0 : _f.description;
            }
        }
        const itemDescription = (_j = (_h = (_g = model.items.groups[0]) === null || _g === void 0 ? void 0 : _g.items) === null || _h === void 0 ? void 0 : _h[0]) === null || _j === void 0 ? void 0 : _j.description;
        const allSegmentsItemDescription = (_m = (_l = (_k = model.items.allSegmentsGroups[0]) === null || _k === void 0 ? void 0 : _k.items) === null || _l === void 0 ? void 0 : _l[0]) === null || _m === void 0 ? void 0 : _m.description;
        return itemDescription !== null && itemDescription !== void 0 ? itemDescription : allSegmentsItemDescription;
    }, [
        props.type,
        props.additionalServices,
        isSelected,
        (_d = model.items.groups[0]) === null || _d === void 0 ? void 0 : _d.items,
        (_e = model.items.allSegmentsGroups[0]) === null || _e === void 0 ? void 0 : _e.items
    ]);
    return children({
        content,
        title,
        description,
        isSelected,
        isLoading: model.isLoading
    });
};
export default CardExtraSummary;
