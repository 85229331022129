import * as React from 'react';
import { initI18n } from '../../utils';
import { useCardExtra } from '../hooks';
import CardExtraSummary from './CardExtraSummary/CardExtraSummary';
import CardExtraContent from './CardExtraContent/CardExtraContent';
import CardExtraModal from './CardExtraModal/CardExtraModal';
initI18n('CardExtra');
const CardExtra = props => {
    const { type, insuranceCodes, userInfo, updateUser } = props;
    const model = useCardExtra(props);
    if (!model.showService) {
        return null;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(CardExtraSummary, { type: type, userInfo: userInfo, gdsServiceType: model.gdsServiceType, flight: model.flight, travellers: model.travellers, additionalServices: model.additionalServicesByType, onServiceDelete: model.onRemoveService }, summaryProps => (React.createElement(CardExtraContent, { type: type, insuranceCodes: insuranceCodes, title: summaryProps.title, description: summaryProps.description, content: summaryProps.content, minPrice: model.cardExtraPrices.minPrice, totalPrice: model.cardExtraPrices.totalPrice, isSelected: summaryProps.isSelected, isLoading: summaryProps.isLoading || model.isLoading, onChangeService: model.cardExtraDisclosure.onOpenModal, onToggleService: model.isSingleService ? model.onToggleService : null, onViewLoyaltyStatus: model.onViewLoyaltyStatus }))),
        React.createElement(CardExtraModal, { type: type, gdsServiceType: model.gdsServiceType, openServiceType: model.cardExtraDisclosure.openServiceType, additionalServices: model.additionalServicesByType, userInfo: userInfo, isOpenModal: model.cardExtraDisclosure.isOpenModal, isCanModifyServices: model.isCanModifyServices, isLoading: model.isLoading, updateUser: updateUser, onSaveServices: model.onSaveServicesRequest, onClose: model.cardExtraDisclosure.onCloseModal })));
};
export default CardExtra;
