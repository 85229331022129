import * as React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../theme';
import { arrowIcon, editIcon } from '../Icons';
import { useIsMobile } from '../../../utils';
var Edit = function (_a) {
    var _b;
    var className = _a.className, onClick = _a.onClick, _c = _a.isOpen, isOpen = _c === void 0 ? false : _c;
    var css = useTheme('Navigation').Edit;
    var t = useTranslation('QuickSearchForm').t;
    var isMobile = useIsMobile();
    return (React.createElement("button", { className: cn(css.button, className), onClick: onClick },
        React.createElement("span", { className: css.button__text }, t('Modify search')),
        React.createElement("span", { className: cn(css.button__icon, (_b = {},
                _b[css.button__icon_reversed] = isOpen,
                _b)) }, isMobile && !isOpen ? editIcon : arrowIcon)));
};
export default Edit;
