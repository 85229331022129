import { useEffect } from 'react';
import { getCurrency, setCurrency } from '@websky/core/src/cache';
export var useDefaultCurrency = function (defaultCurrency) {
    var currency = getCurrency();
    useEffect(function () {
        if (currency !== defaultCurrency) {
            setCurrency(defaultCurrency);
        }
    }, []);
    var onSubmit = function (parameters, onSubmitHandler) {
        if (parameters.currency !== currency && parameters.currency !== defaultCurrency) {
            onSubmitHandler({
                currency: defaultCurrency,
                passengers: parameters.passengers.map(function (passenger) { return ({
                    passengerType: passenger.passengerType,
                    extendedPassengerType: passenger.extendedPassengerType,
                    count: passenger.count
                }); }),
                segments: parameters.segments.map(function (segment) { return ({
                    date: segment.date,
                    departure: { iata: segment.departure.iata },
                    arrival: { iata: segment.arrival.iata }
                }); }),
                promotionCode: parameters.promotionCode || '',
                ffpMode: parameters.ffpMode || false
            });
        }
    };
    return { onSubmit: onSubmit };
};
