import { __assign } from "tslib";
import * as React from 'react';
import { useField } from 'react-final-form';
import Input from '../../Input';
import { useTheme } from '../../theme';
import Tooltip from '../../Tooltip';
var Field = function (_a) {
    var name = _a.name, label = _a.label, validation = _a.validation, textFieldProps = _a.textFieldProps;
    var _b = useField(name, {
        validate: function (value) { return (validation ? validation(value || '') : undefined); },
        type: 'text'
    }), input = _b.input, meta = _b.meta;
    var theme = useTheme('Forms').Field;
    return (React.createElement(Tooltip, { title: meta.error || meta.submitError, open: ((meta.modified && meta.touched) || meta.submitFailed) && (!!meta.error || !!meta.submitError) },
        React.createElement("div", { className: theme.input__wrapper },
            React.createElement(Input, __assign({}, input, { value: input.value, absoluteHintPosition: true, labelClassName: theme.label, labelShrinkClassName: theme.label_shrink, labelErrorClassName: theme.label_error, inputClassName: theme.input__inner, inputFocusedClassName: theme.input_focused, variant: "outlined", TextFieldProps: __assign(__assign(__assign({}, textFieldProps), input), { label: label, fullWidth: false, value: input.value, onChange: function (event) {
                        input.onChange(event);
                    } }) })))));
};
export default Field;
