import { getLocale } from '@websky/core/src/utils';
import { CardType } from '@websky/core/src/PromoLoader/types';
import { OauthServiceType } from '@websky/graphql';
import { Locale } from '@websky/core/src/types';
var currentLocale = getLocale();
var localeLangMap = new Map([['kk', 'kz']]);
var isProd = process.env.NODE_ENV === 'production';
currentLocale = localeLangMap.has(currentLocale) ? localeLangMap.get(currentLocale) : currentLocale;
export var promoLoaders = [
    {
        type: CardType.City,
        city: 'Sevan.city',
        title: 'Sevan.title',
        text: 'Sevan.text',
        image: 'https://cdn.websky.aero/content/RM/images/loaders/Sevan/small.jpg'
    },
    {
        type: CardType.City,
        city: 'RepublicSquare.sity',
        title: 'RepublicSquare.title',
        text: 'RepublicSquare.text',
        image: 'https://cdn.websky.aero/content/RM/images/loaders/Square/small.jpg'
    },
    {
        type: CardType.City,
        city: 'GarniTemple.city',
        title: 'GarniTemple.title',
        text: 'GarniTemple.text',
        image: 'https://cdn.websky.aero/content/RM/images/loaders/Garni/small.jpg'
    },
    {
        type: CardType.City,
        city: 'KhorVirapMonastery.city',
        title: 'KhorVirapMonastery.title',
        text: 'KhorVirapMonastery.text',
        image: 'https://cdn.websky.aero/content/RM/images/loaders/Horvi/small.jpg'
    }
];
var baggageAvailableLocales = ['ru', 'en', 'hy'];
var baggageLocale = baggageAvailableLocales.includes(currentLocale) ? currentLocale : 'en';
var baggageUrl = {
    ru: 'информация/багаж/1',
    en: 'information/baggage/1',
    hy: 'տեղեկություն/ուղեբեռ/1'
};
var privacyPolicyLink;
switch (currentLocale) {
    case Locale.Russian:
        privacyPolicyLink = 'https://airdilijans.com/privacy-policy-ru/';
        break;
    case Locale.Armenian:
        privacyPolicyLink = "https://airdilijans.com/privacy-policy-hy/";
        break;
    default:
        privacyPolicyLink = "https://airdilijans.com/privacy-policy-".concat(currentLocale, "/");
}
export var companyInfo = {
    baggage: "https://airdilijans.com/free-baggage-allowance-".concat(currentLocale),
    meal: null,
    seat: null,
    legal: "https://airdilijans.com/conditions-of-carriage-".concat(currentLocale),
    privacyPolicy: privacyPolicyLink,
    exareRulesURL: "https://airdilijans.com/conditions-of-carriage-".concat(currentLocale),
    exarePrivacyPolicyURL: "https://airdilijans.com/content/documents/privacy-policy-".concat(currentLocale, ".pdf"),
    passengerConductRules: "https://airdilijans.com/conditions-of-carriage-".concat(currentLocale),
    insurance: null,
    loyaltyProgramRegistrationUrl: "",
    loyaltyProgramName: '',
    iataCode: 'RM',
    loyalty: {
        links: [
            {
                title: 'Status',
                url: ""
            },
            {
                title: 'Earn miles',
                url: ""
            },
            {
                title: 'Use miles',
                url: ""
            },
            {
                title: 'FAQ',
                url: ""
            }
        ]
    },
    multiInsurance: true,
    specialDocumentForInsurance: true
};
export var i18n = {
    en: {
        CheckinConditions: {
            'Check-in conditions': 'Check-in conditions',
            OpenCloseCheckinCondition: 'Online check-in opens 20 hours and closes 3 hours before departure',
            BaggageCondition: 'You can check-in your baggage at Air Dilijans counter at the airport. Please check Baggage Rules and Conditions',
            PassengersCondition: 'Please Note: For security reason air company reserves the right to change preserved seat according the direction of pilot-in-command'
        },
        Passenger: {
            Passport: 'Passport RF'
        },
        Consents: {
            agreement_interpolation: ''
        },
        PromoLoader: {
            'Sevan.city': 'Sevan',
            'Sevan.title': 'Sevan Lake',
            'Sevan.text': 'Discover Armenia with us',
            'RepublicSquare.sity': 'Republic Square',
            'RepublicSquare.title': 'Republic Square',
            'RepublicSquare.text': 'Discover Armenia with us',
            'GarniTemple.city': 'Garni temple',
            'GarniTemple.title': 'Garni temple',
            'GarniTemple.text': 'Discover Armenia with us',
            'KhorVirapMonastery.city': 'Khor Virap Monastery',
            'KhorVirapMonastery.title': 'Khor Virap Monastery',
            'KhorVirapMonastery.text': 'Discover Armenia with us'
        },
        LoginPage: {
            'Email / Phone / Card number': 'Email',
            'Please enter a valid Email / Phone / Card number': 'Please enter a valid Email'
        },
        SeatMap: {
            'Dear passengers!\r\n Please note that if you do not meet the above criteria, the airline will have the right to give you another seat during check-in, without any refund.': 'Dear passengers!\r\n Please note that if you do not meet the above criteria, the airline will have the right to give you another seat during check-in, without any refund.'
        },
        Title: {
            companyName: 'Air Dilijans'
        },
        SearchForm: {
            'Add flight': 'Add flight',
            Outbound: 'Departure'
        },
        Forms: {
            Checkin: 'Check-in',
            'Please enter the {{fieldName}} you wish to register for the flight. You can find all the necessary information in the itinerary receipt sent to the buyer via email after booking.': 'Please enter the {{fieldName}} of the passenger you wish to register for the flight. You can find all the necessary information in the itinerary receipt sent to the buyer by email after booking.'
        }
    },
    ru: {
        CheckinConditions: {
            'Check-in conditions': 'Условия регистрации',
            OpenCloseCheckinCondition: 'Онлайн-регистрация открывается за 20 часов и закрывается за 3 часа до вылета',
            BaggageCondition: 'Вы можете зарегистрировать багаж на стойках регистрации Эйр Дилижанс в аэропорту. Пожалуйста, предварительно ознакомьтесь с условиями и правилами перевозки багажа',
            PassengersCondition: 'В целях безопасности полетов, авиакомпания оставляет за собой право изменить место, выбранное Вами на борту, по указанию командира Воздушного Судна'
        },
        Consents: {
            agreement_interpolation: ''
        },
        PromoLoader: {
            'Sevan.city': 'Oзеро Севан',
            'Sevan.title': 'Oзеро Севан',
            'Sevan.text': 'Откройте Армению с нами',
            'RepublicSquare.sity': 'Площадь Республики',
            'RepublicSquare.title': 'Площадь Республики',
            'RepublicSquare.text': 'Откройте Армению с нами',
            'GarniTemple.city': 'Храм Гарни',
            'GarniTemple.title': 'Храм Гарни',
            'GarniTemple.text': 'Откройте Армению с нами',
            'KhorVirapMonastery.city': 'Монастырь Хор Вирап',
            'KhorVirapMonastery.title': 'Монастырь Хор Вирап',
            'KhorVirapMonastery.text': 'Откройте Армению с нами'
        },
        Passenger: {
            Passport: 'Паспорт РФ'
        },
        Refund: {
            'my plans have changed': 'Мои планы изменились',
            Illness: 'Болезнь пассажира члена его / ее семьи, путешествующего вместе с пассажиром, если такое заболевание подтверждено соответствующими медицинскими документами, или в случае смерти пассажира или члена его / ее семьи, если смерть подтверждена соответствующими медицинскими документы, при условии, что об этом сообщается до окончания времени регистрации, указанного в билете',
            FlightCancellationOrDelay: 'Отмена или задержка рейса, указанного в билете',
            Rerouting: 'Изменение маршрута (без учета неблагоприятных погодных условий и форс-мажоров)',
            FlightOperationOutOfSchedule: 'Выполнение полетов вне графика',
            SeatUnavailability: 'Отсутствие места для пассажира на рейс и дату, указанные в билете',
            EnduringSecurityScreening: 'Если пассажир не может подняться на борт самолета из-за длительного досмотра / досмотра, при условии, что во время такого досмотра / досмотра не будет обнаружено никаких запрещенных веществ или предметов',
            FailedEnsureConnectionOfFlights: 'Если перевозчик не обеспечил стыковку рейсов при условии, что стыковочные рейсы являются разовыми',
            FailedToProvideServices: 'Если перевозчик не предоставил пассажиру услуги в соответствии с классом, указанным в билете',
            FailedToIssueTicketAppropriately: 'Если перевозчик или его уполномоченный агент не выписали билет надлежащим образом',
            OtherCases: 'В иных случаях, когда пассажир не обеспечен перевозкой по вине перевозчика'
        },
        LoginPage: {
            'Email / Phone / Card number': 'Эл. почта',
            'Please enter a valid Email / Phone / Card number': 'Пожалуйста, введите Эл. почту'
        },
        SeatMap: {
            'Dear passengers!\r\n Please note that if you do not meet the above criteria, the airline will have the right to give you another seat during check-in, without any refund.': 'Уважаемые пассажиры!\r\n Обращаем ваше внимание, что в случае вашего несоответствия вышеуказанным критериям, авиакомпания оставляет за собой право выдать вам другое место во время регистрации, без возврата денежных средств.'
        },
        Title: {
            companyName: 'Air Dilijans'
        },
        SearchForm: {
            'Add flight': 'Добавить перелет'
        },
        Forms: {
            'My booking': 'Бронирование',
            Checkin: 'Регистрация',
            'Please enter the {{fieldName}} you wish to register for the flight. You can find all the necessary information in the itinerary receipt sent to the buyer via email after booking.': 'Пожалуйста, введите {{fieldName}} пассажира, которого вы хотите зарегистрировать на рейс. Вы можете найти всю необходимую информацию в маршрутной квитанции, отправленной покупателю по электронной почте после бронирования.'
        }
    },
    hy: {
        CheckinConditions: {
            'Check-in conditions': 'Առցանց գրանցման պայմաններ',
            OpenCloseCheckinCondition: 'Առցանց հաշվառումը բացվում է 20 ժամ առաջ և փակվում է մեկնելուց 3 ժամ առաջ',
            BaggageCondition: 'Դուք կարող եք գրանցել ձեր ուղեբեռը օդանավակայանի էյր Դիլիջանս-ի հաշվառման կետերում. Խնդրում ենք ստուգել ուղեբեռի կանոնները և պայմանները',
            PassengersCondition: 'Թռիչքների անվտանգության նկատառումներից ելնելով, Ավիաընկերությունը իրեն իրավունք է վերապահում փոխել ձեր ընտրած նստատեղը օդանավում ՝ օդանավի հրամանատարի ցուցումով'
        },
        Consents: {
            agreement_interpolation: ''
        },
        PromoLoader: {
            'Sevan.city': 'Սևանա լիճ',
            'Sevan.title': 'Սևանա լիճ',
            'Sevan.text': 'Բացահայտեք Հայաստանը մեզ հետ',
            'RepublicSquare.sity': 'Հանրապետության հրապարակ',
            'RepublicSquare.title': 'Հանրապետության հրապարակ',
            'RepublicSquare.text': 'Բացահայտեք Հայաստանը մեզ հետ',
            'GarniTemple.city': 'Գառնու տաճար',
            'GarniTemple.title': 'Գառնու տաճար',
            'GarniTemple.text': 'Բացահայտեք Հայաստանը մեզ հետ',
            'KhorVirapMonastery.city': 'Խոր Վիրապ վանք',
            'KhorVirapMonastery.title': 'Խոր Վիրապ վանք',
            'KhorVirapMonastery.text': 'Բացահայտեք Հայաստանը մեզ հետ'
        },
        LoginPage: {
            'Email / Phone / Card number': 'Էլ',
            'Please enter a valid Email / Phone / Card number': 'Խնդրում ենք մուտքագրել Էլ փոստ'
        },
        SeatMap: {
            'Dear passengers!\r\n Please note that if you do not meet the above criteria, the airline will have the right to give you another seat during check-in, without any refund.': 'Ուշադրություն։\r\n Վերոնշյալ չափանիշներին համապատասխան չլինելու դեպքում, ավիաընկերությունը իրավունք ունի տեղափոխել ուղևորին այլ տեղ, առանց վճարված գումարի փոխհատուցման.'
        },
        Title: {
            companyName: 'Air Dilijans'
        },
        Forms: {
            'Please enter the {{fieldName}} you wish to register for the flight. You can find all the necessary information in the itinerary receipt sent to the buyer via email after booking.': 'Խնդրում ենք մուտքագրել այն ուղևորի {{fieldName}}, ում ցանկանում եք գրանցել թռիչքին: Ամրագրումից հետո գնորդին էլեկտրոնային փոստով ուղարկված երթուղու անդորրագրում` կարող եք գտնել բոլոր անհրաժեշտ տեղեկությունները.',
            'My booking': 'Ամրագրում',
            Checkin: 'Հաշվառում'
        }
    },
    de: {
        LoginPage: {
            'Email / Phone / Card number': 'Email',
            'Please enter a valid Email / Phone / Card number': 'Bitte geben Sie eine gültige Email-Adresse ein'
        },
        Title: {
            companyName: 'Air Dilijans'
        }
    },
    kk: {
        LoginPage: {
            'Email / Phone / Card number': 'Электрондық пошта',
            'Please enter a valid Email / Phone / Card number': 'Жарамды электрондық поштаны енгізіңіз'
        },
        Title: {
            companyName: 'Air Dilijans'
        }
    },
    fr: {
        LoginPage: {
            'Email / Phone / Card number': 'Email',
            'Please enter a valid Email / Phone / Card number': 'Veuillez saisir un e-mail valide'
        },
        Title: {
            companyName: 'Air Dilijans'
        }
    },
    zh: {
        LoginPage: {
            'Email / Phone / Card number': '電子郵件',
            'Please enter a valid Email / Phone / Card number': '請輸入有效電子郵件'
        },
        Title: {
            companyName: 'Air Dilijans'
        }
    },
    ka: {
        LoginPage: {
            'Email / Phone / Card number': 'ელფოსტა ფოსტა',
            'Please enter a valid Email / Phone / Card number': 'გთხოვთ, შეიყვანოთ სწორი ელფოსტა'
        },
        FullscreenDialog: {
            Close: 'Փակել'
        },
        Title: {
            companyName: 'Air Dilijans'
        }
    }
};
var passengersConfig = [
    {
        code: 'ADT',
        isAdult: true,
        defaultCount: 1
    },
    {
        code: 'CLD',
        isAdult: false,
        maxCountWithoutAdult: 0
    },
    {
        code: 'INF',
        isAdult: false
    },
    {
        code: 'INS',
        isAdult: false,
        disabled: true
    }
];
export var config = {
    global: {
        socialAuthMethods: [
            {
                service: OauthServiceType.Google,
                isAvailable: true
            }
        ],
        authMethods: {
            phone: false,
            ffp: false
        },
        yandexMetrikaId: isProd ? '91592842' : undefined,
        showIsActualizationResultOk: false,
        companyInfo: companyInfo,
        maxBaggagePerPassenger: 4,
        i18n: i18n,
        reserveEngines: {
            redirectToWebsky1: true,
            checkin: "https://wci.armeniafly.com/check-in/?lang=".concat(currentLocale),
            siteUrl: 'https://armeniafly.com/'
        },
        findOrderURL: "https://www.armeniafly.com/booking-".concat(currentLocale, "/#/find/"),
        roundMoneyAmount: true
    },
    Engine: {
        calendarDaysCount: 5,
        promoLoaders: promoLoaders,
        checkInEnabled: true
    },
    Account: {
        engineURL: 'https://armeniafly.com/booking-ru/'
    },
    SearchForm: {
        showPriceGraph: true,
        enableComplexRoute: true,
        showPricesInDatepicker: true,
        offerHotels: true,
        offerHotelsSupplier: 'OSTROVOK',
        offerHotelsLink: 'https://airdilijans.zenhotels.com/hotels',
        offerHotelsMainLink: 'https://airdilijans.zenhotels.com',
        selectableCurrencies: [
            {
                code: 'AMD',
                symbol: '֏'
            },
            {
                code: 'USD',
                symbol: '$'
            },
            {
                code: 'EUR',
                symbol: '€'
            },
            {
                code: 'RUB',
                symbol: '₽'
            }
        ],
        passengersConfig: passengersConfig
    }
};
