import * as React from 'react';
import { useContext } from 'react';
import { initI18n } from '@websky/core/src/utils';
import { useTranslation } from 'react-i18next';
import SeatMapDisclaimer from '@websky/core/src/SeatMap/components/SeatMapDisclaimer/SeatMapDisclaimer';
import css from './SeatMapAdditionalDisclaimer.css';
import { useSelector } from 'react-redux';
import { getSelectedServices } from '@websky/core/src/Checkout/store/selectedServices/selectors';
import { getSeatMap } from '@websky/core/src/Checkout/store/order/selectors';
import { ModeContext } from '@websky/core/src/context';
import { Mode } from '@websky/core/src/types';
initI18n('SeatMap');
var SeatMapAdditionalDisclaimer = function (props) {
    var t = useTranslation('SeatMap').t;
    var mode = useContext(ModeContext);
    if (mode === Mode.Checkin) {
        return null;
    }
    var selectedServices = useSelector(getSelectedServices);
    var seatMap = useSelector(getSeatMap);
    var hasSelectedSeatAtExit = React.useMemo(function () {
        return selectedServices.some(function (services) {
            var _a;
            return (_a = services.seats) === null || _a === void 0 ? void 0 : _a.some(function (seat) {
                var _a, _b;
                return Number(seat.segmentId) === props.segmentIndex &&
                    ((_b = (_a = seatMap.segments[props.segmentIndex].decks[0]) === null || _a === void 0 ? void 0 : _a.rows) === null || _b === void 0 ? void 0 : _b.some(function (row) { return row.number === seat.row && row.exitRow; }));
            });
        });
    }, [selectedServices, props.segmentIndex]);
    if (!hasSelectedSeatAtExit) {
        return null;
    }
    return (React.createElement(SeatMapDisclaimer, null,
        React.createElement("p", { className: css.disclaimer }, t("Dear passengers!\r\n Please note that if you do not meet the above criteria, the airline will have the right to give you another seat during check-in, without any refund."))));
};
export default SeatMapAdditionalDisclaimer;
