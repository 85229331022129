import { __rest } from "tslib";
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Field, Form } from 'react-final-form';
import { FormControlLabel, Checkbox } from '@material-ui/core';
import cn from 'classnames';
import AdditionalServiceWrapper from '../../../../AdditionalServiceWrapper';
import Money from '../../../../Money/components/Money';
import PhoneDropdown from '../../../../BaseComponents/PhoneDropdown';
import { useTheme } from '../../../../theme';
import { useSmsPopupFormData, useSmsPopupValidation } from './hooks';
import { useIsMobile } from '../../../../utils';
import { TravellerFields } from '../../../../TravellerForm/types';
import { getFormTravellerKey, getTotalPrice, getTravellerLabel } from '../utils';
const SmsPopup = ({ className, smsNotificationId, title, description, travellers, customer, price, onClose, onSubmit }) => {
    var _a;
    const { SmsPopup: css } = useTheme('SmsNotifications');
    const { t } = useTranslation('Checkout');
    const isMobile = useIsMobile();
    const { validate } = useSmsPopupValidation((_a = customer.values.find(({ name: valueName }) => valueName === TravellerFields.Phone)) === null || _a === void 0 ? void 0 : _a.validationRules[0].regExp);
    const { initialFormData, clearFormData, travellersPhoneOptions } = useSmsPopupFormData(travellers, customer, smsNotificationId);
    return (React.createElement(Form, { initialValues: initialFormData, onSubmit: onSubmit, validate: validate }, ({ handleSubmit, form, values, dirty }) => (React.createElement("form", null,
        React.createElement(AdditionalServiceWrapper, { className: cn(className, css.wrapper), headerClassName: css.header, contentClassName: css.content, header: title, onCancel: isMobile ? onClose : undefined, totalPrice: getTotalPrice(values, price), onConfirm: dirty ? handleSubmit : onClose, onClear: () => clearFormData(form) },
            React.createElement(React.Fragment, null,
                description && React.createElement("div", { className: css.description }, description),
                travellers.map(traveller => {
                    const key = getFormTravellerKey(traveller.id);
                    return (React.createElement("div", { className: css.passenger },
                        React.createElement(Field, { name: `${key}.selected`, type: "checkbox" }, field => (React.createElement(FormControlLabel, { label: getTravellerLabel(traveller), classes: {
                                label: css.passenger__checkboxLabel
                            }, control: React.createElement(Checkbox, Object.assign({}, field, { checked: field.input.value, classes: {
                                    root: css.checkbox,
                                    checked: css.checkbox_checked
                                }, disableRipple: true, onChange: e => field.input.onChange(e) })) }))),
                        React.createElement("div", { className: css.passenger__controls },
                            React.createElement(Field, { name: `${key}.phone` }, field => {
                                const isDisabled = !values[key].selected;
                                const error = !isDisabled && field.meta.touched && field.meta.error;
                                const _a = field.input, { onChange, multiple } = _a, input = __rest(_a, ["onChange", "multiple"]);
                                return (React.createElement(PhoneDropdown, Object.assign({ className: css.passenger__dropdown, options: travellersPhoneOptions, InputProps: {
                                        label: t('Phone number'),
                                        error,
                                        helperText: error ? field.meta.error : undefined
                                    } }, input, { disabled: isDisabled, onInputChange: onChange, onChange: onChange })));
                            }),
                            price && (React.createElement("div", { className: css.passenger__price },
                                React.createElement(Money, { money: price }))))));
                })))))));
};
export default SmsPopup;
