import * as React from 'react';
import { useCallback, useMemo } from 'react';
import cn from 'classnames';
import { ThemeProvider, useTheme } from '../../../theme';
import { useCardExtraModal } from './cardExtraModal.hook';
import { useCardExtraIconSource } from '../../hooks';
import ServiceCardDialog from '../../../Checkout/components/Checkout/OrderPaidServiceCards/components/ServiceCardDialog/ServiceCardDialog';
import ServicePage from '../../../Checkout/components/Checkout/ServicePage/ServicePage';
import Close from '../../../CheckoutAdditionalService/components/Close/Close';
import Modal from '../../../Modal';
import PetPopup from '../../../CheckoutAdditionalService/components/PetPopup/PetPopup';
import AeroexpressPopup from '../../../CheckoutAdditionalService/components/AeroexpressPopup/AeroexpressPopup';
import InsurancePopup from '../../../InsurancePopup';
import VipServiceSelect from '../../../VipServiceSelect';
import LoyaltyConnect from '../../../Loyalty/components/LoyaltyStub/LoyaltyConnect/LoyaltyConnect';
import ImageWithFallback from '../../../ImageWithFallback';
import { useIsMobile } from '../../../utils';
import SmsPopup from '../../../CheckoutAdditionalService/components/SmsNotifications/components/SmsPopup';
import { getUserInfo } from '../../../Checkout/utils';
import { useConfigRedirects } from '../../../hooks';
const CardExtraModal = props => {
    const { type, isOpenModal, onClose } = props;
    const { CardExtraModal: theme } = useTheme('CardExtra');
    const model = useCardExtraModal(props);
    const iconSource = useCardExtraIconSource(type);
    const { onGoToAccountSettings } = useConfigRedirects();
    const isMobile = useIsMobile();
    const renderCloseButton = useCallback((onClick) => React.createElement(Close, { onClick: onClick }), []);
    const ServiceModal = useMemo(() => {
        switch (type) {
            case 'animal':
                return PetPopup;
            case 'transfer':
                return AeroexpressPopup;
            case 'insurance':
                return InsurancePopup;
            case 'business-lounge':
            case 'priority-boarding':
            case 'baggage-delivery':
            case 'capsule-hotel':
                return VipServiceSelect;
            case 'sms':
                return SmsPopup;
            default:
                return null;
        }
    }, [type]);
    const modalClasses = {
        root: cn(theme.root, {
            [theme.root_animal]: type === 'animal',
            [theme.root_baggageDelivery]: type === 'baggage-delivery',
            [theme.root_businessLounge]: type === 'business-lounge',
            [theme.root_priorityBoarding]: type === 'priority-boarding',
            [theme.root_insurance]: type === 'insurance',
            [theme.root_transfer]: type === 'transfer',
            [theme.root_capsuleHotel]: type === 'capsule-hotel',
            [theme.root_loyalty]: type === 'loyalty'
        }),
        scrollBody: theme.root__scrollBody,
        paper: theme.root__paper
    };
    // Full screen modal for seats, baggage and meal services
    if (model.isFullscreenDialog) {
        return (React.createElement(ServicePage, null, childrenProps => React.createElement(ServiceCardDialog, Object.assign({}, model.serviceDialogModel, childrenProps))));
    }
    if (type === 'loyalty') {
        const onUpdateUser = () => {
            var _a;
            (_a = props.updateUser) === null || _a === void 0 ? void 0 : _a.call(props);
            onClose();
        };
        return (React.createElement(LoyaltyConnect, { isOpen: isOpenModal, onClose: onClose, authType: "authenticate", userInfo: getUserInfo(props.userInfo), onLoyaltyCardSaved: onUpdateUser, onGoToSettings: onGoToAccountSettings }));
    }
    if (!ServiceModal) {
        return null;
    }
    return (React.createElement(ThemeProvider, { value: {
            VipServiceSelect: {
                VipServiceSelect: { header: theme.header }
            }
        } },
        React.createElement(Modal, { classes: modalClasses, maxWidth: model.maxWidth, open: isOpenModal, onClose: onClose, closeButtonRenderer: renderCloseButton, slideUp: isMobile },
            React.createElement(ServiceModal, Object.assign({}, Object.assign(Object.assign({}, model.servicePopupModel), { headerIcon: React.createElement(ImageWithFallback, Object.assign({}, iconSource)), isLoading: props.isLoading }))))));
};
export default CardExtraModal;
