import { __assign, __rest } from "tslib";
import * as React from 'react';
import FastSearchBase from '@websky/core/src/Engine/components/FastSearch/FastSearch';
import { set } from '@websky/core/src/cache';
import { isFastSearchCorrect, fastSearchParse } from '@websky/core/src/Engine/components/FastSearch/utils';
import { Currency } from '@websky/core/src/enums';
import { useDefaultCurrency } from '../hooks';
import { Log } from '@websky/core/src/sentry';
export var IS_FAST_SEARCH = 'IS_FAST_SEARCH';
var FastSearch = function (_a) {
    var startSearch = _a.startSearch, props = __rest(_a, ["startSearch"]);
    var url = new URL(location.href);
    var fastSearchString = url.hash.slice(url.hash.lastIndexOf('/') + 1);
    if (isFastSearchCorrect(fastSearchString)) {
        Log({
            message: 'get currency from fast search string'
        });
        var searchParams = fastSearchParse(fastSearchString);
        useDefaultCurrency(searchParams.currency);
    }
    else {
        useDefaultCurrency(Currency.AMD);
    }
    var startSearchHandler = React.useCallback(function (params) {
        startSearch(params);
        set(IS_FAST_SEARCH, 'true');
    }, [startSearch]);
    return React.createElement(FastSearchBase, __assign({}, props, { startSearch: startSearchHandler }));
};
export default FastSearch;
