import { __values } from "tslib";
import i18n from 'i18next';
import { AviaPassengerType } from '@websky/graphql';
import { RouteType } from './types';
import { SPECIAL_CATEGORIES } from '../SearchForm/utils';
import { PassengerType } from '../enums';
import { getPassengerTypeLabel } from '../utils';
import { format } from '../utils';
export var getRouteType = function (segments) {
    var result = RouteType.ComplexRoute;
    if (segments.length === 1) {
        result = RouteType.OneWay;
    }
    else if (segments.length === 2 && segments[0].departure.iata === segments[1].arrival.iata) {
        result = RouteType.RoundTrip;
    }
    return result;
};
export var getPassengersCount = function (passengers) {
    return passengers.reduce(function (result, passenger) { return result + passenger.count; }, 0);
};
export var getPassengersSummary = function (passengers) {
    var parsedPassengers = [];
    var numOfInfants = 0;
    var specialCategoriesMap = new Map();
    passengers.forEach(function (passenger) {
        var _a;
        // Count all types of infants.
        if (passenger.passengerType === AviaPassengerType.INS || passenger.passengerType === AviaPassengerType.INF) {
            numOfInfants += passenger.count;
        }
        else if (SPECIAL_CATEGORIES.includes(passenger.extendedPassengerType)) {
            var passengerType = passenger.extendedPassengerType;
            if (passengerType === PassengerType.RetireeF) {
                passengerType = PassengerType.RetireeM;
            }
            specialCategoriesMap.set(passengerType, ((_a = specialCategoriesMap.get(passengerType)) !== null && _a !== void 0 ? _a : 0) + passenger.count);
        }
        else {
            parsedPassengers.push(passenger);
        }
    });
    specialCategoriesMap.forEach(function (count, type) {
        parsedPassengers.push({
            passengerType: AviaPassengerType.ADT,
            extendedPassengerType: type,
            count: count
        });
    });
    if (numOfInfants) {
        parsedPassengers.push({
            passengerType: AviaPassengerType.INF,
            extendedPassengerType: null,
            count: numOfInfants
        });
    }
    var filterPassengers = parsedPassengers.filter(function (passenger) { return passenger.count > 0; });
    if (filterPassengers.length >= 3) {
        var count = getPassengersCount(filterPassengers);
        return ["".concat(count, " ").concat(i18n.t('QuickSearchForm:passenger', { count: count }))];
    }
    return filterPassengers.map(function (passenger) {
        if (passenger.extendedPassengerType) {
            return "".concat(passenger.count, " ").concat(i18n.t("QuickSearchForm:".concat(passenger.extendedPassengerType), {
                count: passenger.count
            }));
        }
        return "".concat(passenger.count, " ").concat(getPassengerTypeLabel(passenger.passengerType, passenger.extendedPassengerType, passenger.count));
    });
};
export var getDatesSummary = function (segments) {
    var e_1, _a;
    var prevDate = null;
    var parsedDates = [];
    try {
        for (var segments_1 = __values(segments), segments_1_1 = segments_1.next(); !segments_1_1.done; segments_1_1 = segments_1.next()) {
            var segment = segments_1_1.value;
            var date = new Date(segment.date);
            if (prevDate) {
                parsedDates.push("".concat(format(prevDate, prevDate.getMonth() !== date.getMonth() ? 'dd MMM' : 'dd')));
            }
            prevDate = date;
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (segments_1_1 && !segments_1_1.done && (_a = segments_1.return)) _a.call(segments_1);
        }
        finally { if (e_1) throw e_1.error; }
    }
    parsedDates.push(format(prevDate, 'dd MMM'));
    return parsedDates;
};
