import * as React from 'react';
import cn from 'classnames';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../theme';
import { getDatesSummary, getPassengersCount, getPassengersSummary, getRouteType } from '../../utils';
import { MOBILE_MIN_WIDTH, TABLET_MIN_WIDTH } from '../../../utils';
import { RouteType } from '../../types';
import { owIcon } from '../../Icons';
import Edit from '../../../widgets/navigation/Edit/Edit';
const Summary = ({ className, parameters, onClick, isOpen = false, renderPromoCode }) => {
    var _a, _b, _c, _d, _e, _f;
    const { Summary: css } = useTheme('QuickSearchForm');
    const { t } = useTranslation('QuickSearchForm');
    const routeType = getRouteType(parameters.segments);
    const isMobile = useMediaQuery({ maxWidth: +MOBILE_MIN_WIDTH + 1 });
    const displayIataCodes = React.useMemo(() => {
        var _a, _b;
        const departureCityName = ((_a = parameters.segments[0].departure.city) === null || _a === void 0 ? void 0 : _a.name) || '';
        const arrivalCityName = ((_b = parameters.segments[0].arrival.city) === null || _b === void 0 ? void 0 : _b.name) || '';
        if (!departureCityName.length || !arrivalCityName.length) {
            return true;
        }
        const allowedRouteLength = departureCityName.length + arrivalCityName.length >= 15;
        if (isMobile) {
            return allowedRouteLength || parameters.segments.length >= 2;
        }
        else if (routeType === RouteType.ComplexRoute) {
            return allowedRouteLength || parameters.segments.length > 2;
        }
        return allowedRouteLength;
    }, [parameters.segments, isMobile, routeType]);
    const datesSummary = React.useMemo(() => {
        return getDatesSummary(parameters.segments);
    }, [parameters.segments]);
    const passengersSummary = React.useMemo(() => {
        return getPassengersSummary(parameters.passengers).join(', ');
    }, [parameters.passengers]);
    let departureName = ((_a = parameters.segments[0].departure) === null || _a === void 0 ? void 0 : _a.name) || '';
    let arrivalName = ((_b = parameters.segments[0].arrival) === null || _b === void 0 ? void 0 : _b.name) || '';
    if (((_c = parameters.segments[0].departure.city) === null || _c === void 0 ? void 0 : _c.name) && ((_d = parameters.segments[0].arrival.city) === null || _d === void 0 ? void 0 : _d.name)) {
        departureName = parameters.segments[0].departure.city.name;
        arrivalName = parameters.segments[0].arrival.city.name;
    }
    if (!departureName || !arrivalName) {
        departureName = parameters.segments[0].departure.iata;
        arrivalName = parameters.segments[0].arrival.iata;
    }
    return (React.createElement("div", { className: cn(css.summary, className), onClick: onClick },
        React.createElement("span", { className: css.route },
            (routeType === RouteType.OneWay || routeType === RouteType.RoundTrip) && (React.createElement(React.Fragment, null,
                React.createElement(MediaQuery, { maxWidth: TABLET_MIN_WIDTH - 1 },
                    displayIataCodes
                        ? parameters.segments[0].departure.iata
                        : ((_e = parameters.segments[0].departure.city) === null || _e === void 0 ? void 0 : _e.name) || '',
                    React.createElement("span", { className: css.route__separator }, "\u2014"),
                    displayIataCodes
                        ? parameters.segments[0].arrival.iata
                        : ((_f = parameters.segments[0].arrival.city) === null || _f === void 0 ? void 0 : _f.name) || ''),
                React.createElement(MediaQuery, { minWidth: TABLET_MIN_WIDTH },
                    departureName,
                    React.createElement("span", { className: css.route__separator }, "\u2014"),
                    arrivalName))),
            routeType === RouteType.ComplexRoute &&
                parameters.segments.map((segment, index) => (React.createElement(React.Fragment, { key: index },
                    displayIataCodes ? segment.departure.iata : segment.departure.city.name,
                    React.createElement("span", { className: css.route__icon }, owIcon),
                    displayIataCodes
                        ? index === parameters.segments.length - 1 && segment.arrival.iata
                        : index === parameters.segments.length - 1 && segment.arrival.city.name)))),
        React.createElement("span", { className: css.dates }, datesSummary.map(date => (React.createElement("span", { key: date }, date)))),
        React.createElement(MediaQuery, { minWidth: TABLET_MIN_WIDTH },
            React.createElement("span", { className: css.passengers }, passengersSummary)),
        React.createElement(MediaQuery, { maxWidth: TABLET_MIN_WIDTH },
            React.createElement("span", { className: css.passengers },
                getPassengersCount(parameters.passengers),
                ' ',
                parameters.passengers.length === 1 && !parameters.passengers[0].extendedPassengerType
                    ? t(parameters.passengers[0].passengerType, { count: parameters.passengers[0].count })
                    : t('passenger', { count: getPassengersCount(parameters.passengers) }))),
        React.createElement("div", { className: css.editButtonWrapper },
            React.createElement(Edit, { isOpen: isOpen })),
        renderPromoCode && renderPromoCode()));
};
export default Summary;
