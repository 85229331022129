import { __awaiter } from "tslib";
import * as React from 'react';
import { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useCheckinSaveOrderServicesMutation } from '@websky/graphql';
import { getCheckinOrder, getExtrasPrice, getTotalPrice } from '../../../../store/order/selectors';
import MediaQuery from '../../../../../../MediaQuery/MediaQuery';
import MobileToolbar from '../../../../../../MobileToolbar';
import Toolbar from '../../../../../../Toolbar';
import { CheckinStep } from '../../../../types';
import SimpleLoader from '../../../../../../SimpleLoader';
import AdditionalServices from '../../../../../../AdditionalServices/components/AdditionalServices';
import { useTheme } from '../../../../../../theme';
import { Money } from '../../../../../../Money';
import { useGetGoBack } from '../utils';
import Consents from '../../../../../../Consents';
// import AdditionalServicesV2 from '../../AdditionalServices/AdditionalServices';
import { useCustomCssVariable, useOffsetTop } from '../../../../../../hooks';
import { useRenderers } from '../../../../../../renderProps';
const Extras = ({ onProceed, onSetOrder }) => {
    const css = useTheme('Checkin').Checkin;
    const { t } = useTranslation('Checkin');
    const rootNode = useRef();
    const [isLoading, setIsLoading] = useState(false);
    const offsetTop = useOffsetTop(rootNode);
    useCustomCssVariable('extras-offset-top', `${offsetTop.toString(10)}px`);
    const order = useSelector(getCheckinOrder);
    const totalPrice = useSelector(getTotalPrice);
    const extrasPriceToPay = useSelector(getExtrasPrice);
    const [saveCheckinOrderServicesMutation] = useCheckinSaveOrderServicesMutation();
    const onProceedHandler = useCallback((onConfirmConsents) => () => __awaiter(void 0, void 0, void 0, function* () {
        if (onConfirmConsents) {
            onConfirmConsents();
            return;
        }
        setIsLoading(true);
        yield onProceed();
        setIsLoading(false);
    }), [onProceed, setIsLoading]);
    const saveCheckinOrderServices = useCallback((params) => __awaiter(void 0, void 0, void 0, function* () {
        const { data } = yield saveCheckinOrderServicesMutation({ variables: { params } });
        return data;
    }), []);
    const goBack = useGetGoBack(CheckinStep.Extras);
    const { CheckinServices } = useRenderers();
    return (React.createElement(React.Fragment, null,
        isLoading && React.createElement(SimpleLoader, null),
        React.createElement("div", { className: css.extras, ref: rootNode },
            React.createElement("div", { className: css.wrapper },
                CheckinServices ? (React.createElement(CheckinServices, null)) : (React.createElement(AdditionalServices, { additionalServices: order.additionalServices, className: css.additionalServices, orderId: order.id, travellers: order.travellers.map(passenger => (Object.assign(Object.assign({}, passenger), { supplierTravellerType: null, needOthsSsr: null, subsidyInfo: null, fares: null }))), saveOrderServices: saveCheckinOrderServices, onSetOrder: onSetOrder })),
                React.createElement(Consents, null, ({ onConfirm }) => (React.createElement(React.Fragment, null,
                    React.createElement(MediaQuery, { maxWidth: "mobile" },
                        React.createElement(MobileToolbar, { onBack: goBack, onContinue: onProceedHandler(onConfirm) })),
                    React.createElement(MediaQuery, { minWidth: "mobile" },
                        React.createElement(Toolbar, { totalPrice: totalPrice, additional: extrasPriceToPay.amount ? (React.createElement("div", { className: css.servicePrice },
                                t('Extras'),
                                ": ",
                                React.createElement(Money, { money: extrasPriceToPay }))) : null, onBackButtonClick: goBack, onButtonClick: onProceedHandler(onConfirm) })))))))));
};
export default Extras;
