import * as React from 'react';
import { memo } from 'react';
import MealComponent from '../../Meal';
import { MealViewMode } from '../../Meal/types';
import memes from '../../memes';
import WebskyAnalyticsApollo from '../../DataLayerAnalytics/WebskyAnalyticsApollo';
import { WxEvents } from '../../DataLayerAnalytics/types';
import { calculateMaxServiceCount } from '../../shared/lib/calculateMaxServiceCount';
const reduceSelectedMealsCount = memes((total, selectedMeal, meal, passengerId, segmentId, mode) => {
    if (!selectedMeal.confirmedCount) {
        selectedMeal.confirmedCount = 0;
    }
    let count = total;
    if (meal.id === selectedMeal.mealId &&
        segmentId === selectedMeal.segmentId &&
        passengerId === selectedMeal.passengerId) {
        count = total + selectedMeal.count - selectedMeal.confirmedCount;
        if (mode === MealViewMode.confirmed) {
            count = total + selectedMeal.confirmedCount;
        }
    }
    return count;
});
export const MealWrapper = memo(({ selectedMeals, initialSelectedMeals, meal, segmentId, passengers, gdsServices, onMealAdd, onMealRemove, onMealConfirm, onMealAddForPassengers, onMealClear, onMealOpen, readonly = false, mode = MealViewMode.select, fullSize, isRecommend, isLoading }) => {
    const isCountChanged = React.useMemo(() => {
        const filteredSelectedMeals = selectedMeals
            .filter(it => it.segmentId === segmentId && it.mealId === meal.id)
            .map(it => {
            return { passengerId: it.passengerId, count: it.count };
        });
        const filteredInitialSelectedMeals = initialSelectedMeals
            .filter(it => it.segmentId === segmentId && it.mealId === meal.id)
            .map(it => {
            return { passengerId: it.passengerId, count: it.count };
        });
        return JSON.stringify(filteredSelectedMeals) !== JSON.stringify(filteredInitialSelectedMeals);
    }, [selectedMeals]);
    const onMealAddHandler = (meal, passengerId, count) => {
        onMealAdd(meal, segmentId, passengerId, count);
    };
    const onMealAddForPassengerHandler = (meal) => {
        onMealAddForPassengers(meal, segmentId, meal.allowedPassengers, 1);
    };
    const onMealRemoveHandler = (meal, passengerId, count) => {
        onMealRemove(meal, segmentId, passengerId, count);
    };
    const onCloseHandler = (meal, segmentId) => {
        if (isCountChanged) {
            onMealClear(meal, segmentId);
            initialSelectedMeals.forEach(initialSelectedMeal => {
                if (initialSelectedMeal.segmentId === segmentId && meal.id === initialSelectedMeal.mealId) {
                    onMealAddHandler(meal, initialSelectedMeal.passengerId, initialSelectedMeal.count);
                }
            });
        }
    };
    const onConfirmHandler = (meal, segmentId) => {
        if (isCountChanged) {
            onMealConfirm(meal, segmentId);
        }
    };
    const onPopupOpened = () => {
        onMealOpen === null || onMealOpen === void 0 ? void 0 : onMealOpen();
        WebskyAnalyticsApollo.dispatchWxViewEvent(WxEvents.MealItem, { servicesIds: [meal.id], segmentId });
    };
    return (React.createElement(MealComponent, { key: `${meal.id}_${segmentId}`, meal: meal, fullSize: fullSize, onAddForAll: onMealAddForPassengerHandler, onIncrease: (meal, passenger) => onMealAddHandler(meal, passenger.id, 1), onDecrease: (meal, passenger) => onMealRemoveHandler(meal, passenger.id, 1), onClear: meal => onMealClear(meal, segmentId), onConfirm: meal => onConfirmHandler(meal, segmentId), onClose: meal => onCloseHandler(meal, segmentId), readonly: readonly || !meal.canBeAdded, onPopupOpened: onPopupOpened, isCountChanged: isCountChanged, counters: passengers
            .filter(passenger => meal.allowedPassengers.some(passengerId => passengerId === passenger.id))
            .map(passenger => ({
            passenger: {
                id: passenger.id,
                name: passenger.label
            },
            count: selectedMeals.reduce((total, selectedMeal) => reduceSelectedMealsCount(total, selectedMeal, meal, passenger.id, segmentId, mode), 0),
            minAvailable: 0,
            // selectedMeals.find(
            // 	sm =>
            // 		sm.segmentId === segmentId &&
            // 		sm.passengerId === passenger.id &&
            // 		sm.mealId === meal.id
            // )?.confirmedCount ?? 0,
            maxAvailable: calculateMaxServiceCount(passengers, gdsServices, meal.id, segmentId)
        })), isLoading: isLoading, mode: mode }));
});
