import { __awaiter } from "tslib";
import { useState, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getAdditionalServices, getBrandIncludedBaggage, getFlight, getIsAllSegmentsAvailableOnlyOnCheckin, getOrder, getTravellers } from '../Checkout/store/order/selectors';
import { getSelectedServicesState } from '../Checkout/store/selectedServices/selectors';
import { useCart } from '../Checkout/components/Checkout/Stepbar/hooks';
import { getAllSegmentsServicePrice, getMinPrice, getTotalPrice } from '../utils';
import { useCartPrices } from '../Cart/hooks';
import { canBeModifyService, extraGdsServiceToServicePayload, filterGdsServicesByType, getCartPriceKeyByType, getGdsServiceTypeByServiceType, getPassengerPrices, getServicesPrices, getServiceVisible, getUnconfirmedServices, isSingleService as getIsSingleService } from './utils';
import { useConfig } from '../context';
import { useOrderServices } from '../Checkout/hooks';
export function useCardExtra({ type, insuranceCodes }) {
    // Hooks
    const { global: { companyInfo: { loyaltyProgramAccountUrl } } } = useConfig();
    const cardExtraService = useCardExtraService({ type, insuranceCodes });
    const cardExtraDisclosure = useCardExtraDisclosure(type);
    const cardExtraPrices = useCardExtraPrices({
        type,
        insuranceCodes,
        additionalServices: cardExtraService.additionalServicesByType
    });
    // Methods
    const onSaveServicesRequest = useCallback((serviceIds) => __awaiter(this, void 0, void 0, function* () {
        const data = yield cardExtraService.onSaveServicesRequest(serviceIds);
        cardExtraDisclosure.onCloseModal();
        return data;
    }), [cardExtraService.onSaveServicesRequest, cardExtraDisclosure.onCloseModal]);
    const onToggleService = useCallback(() => __awaiter(this, void 0, void 0, function* () {
        var _a, _b, _c, _d;
        if (cardExtraService.isSingleService) {
            if (type === 'insurance') {
                const insuranceCode = (_a = cardExtraService.additionalServicesByType.insurances.insurancePrograms[0]) === null || _a === void 0 ? void 0 : _a.code;
                const isSelected = (_b = cardExtraService.additionalServices.insurances.selectedInsurances) === null || _b === void 0 ? void 0 : _b.some(insurance => insurance.insuranceProgram.code === insuranceCode);
                if (isSelected) {
                    yield cardExtraService.onRemoveServices([{ code: insuranceCode }]);
                }
                else {
                    yield cardExtraService.onAddServices([{ code: insuranceCode }]);
                }
            }
            else if (type === 'transfer') {
                const tripId = cardExtraService.additionalServicesByType.transfer.aeroexpress.trips[0].id;
                const isSelected = (_d = (_c = cardExtraService.additionalServicesByType.transfer.aeroexpress) === null || _c === void 0 ? void 0 : _c.selectedTrips) === null || _d === void 0 ? void 0 : _d.some(trip => trip.id === tripId);
                if (isSelected) {
                    yield cardExtraService.onRemoveServices([{ id: tripId }]);
                }
                else {
                    yield cardExtraService.onAddServices([{ id: tripId }]);
                }
            }
        }
    }), [type, cardExtraService.isSingleService, cardExtraService.additionalServicesByType]);
    const onAddServices = useCallback((services) => __awaiter(this, void 0, void 0, function* () {
        yield cardExtraService.onAddServices(services);
        cardExtraDisclosure.onCloseModal();
    }), [cardExtraService.onAddServices, cardExtraDisclosure.onCloseModal]);
    const onRemoveServices = useCallback((services) => __awaiter(this, void 0, void 0, function* () {
        yield cardExtraService.onRemoveServices(services);
        cardExtraDisclosure.onCloseModal();
    }), [cardExtraService.onRemoveServices, cardExtraDisclosure.onCloseModal]);
    const onViewLoyaltyStatus = useCallback(() => {
        const loyaltyUrl = loyaltyProgramAccountUrl !== null && loyaltyProgramAccountUrl !== void 0 ? loyaltyProgramAccountUrl : '/account/loyalty';
        window.open(loyaltyUrl, '_blank');
    }, [loyaltyProgramAccountUrl]);
    return {
        // data
        order: cardExtraService.order,
        flight: cardExtraService.flight,
        travellers: cardExtraService.travellers,
        additionalServices: cardExtraService.additionalServices,
        additionalServicesByType: cardExtraService.additionalServicesByType,
        unconfirmedServices: cardExtraService.unconfirmedServices,
        selectedServices: cardExtraService.selectedServices,
        gdsServiceType: cardExtraService.gdsServiceType,
        requestError: cardExtraService.requestError,
        showService: cardExtraService.showService,
        isSingleService: cardExtraService.isSingleService,
        isCanModifyServices: cardExtraService.isCanModifyServices,
        isLoading: cardExtraService.isLoading,
        cardExtraPrices,
        cardExtraDisclosure,
        // methods
        onSaveServicesRequest,
        onToggleService,
        onAddServices,
        onRemoveServices,
        onRemoveUnconfirmedServices: cardExtraService.onRemoveUnconfirmedServices,
        onRemoveService: cardExtraService.onRemoveService,
        onViewLoyaltyStatus
    };
}
export function useCardExtraService({ type, insuranceCodes }) {
    // Hooks
    const cart = useCart();
    const orderServices = useOrderServices();
    const { global: { servicesAvailable } } = useConfig();
    // Data
    const order = useSelector(getOrder);
    const flight = useSelector(getFlight);
    const travellers = useSelector(getTravellers);
    const additionalServices = useSelector(getAdditionalServices);
    const selectedServices = useSelector(getSelectedServicesState);
    const brandIncludedBaggage = useSelector(getBrandIncludedBaggage);
    const gdsServiceType = useMemo(() => getGdsServiceTypeByServiceType(type), [type]);
    const additionalServicesByType = useMemo(() => {
        return filterGdsServicesByType(type, flight, additionalServices, insuranceCodes, servicesAvailable.animal);
    }, [type, flight, additionalServices, insuranceCodes, servicesAvailable.animal]);
    const unconfirmedServices = useMemo(() => {
        return getUnconfirmedServices(gdsServiceType, travellers, selectedServices);
    }, [gdsServiceType, travellers, selectedServices]);
    const isSingleService = useMemo(() => {
        return getIsSingleService(type, additionalServicesByType);
    }, [type, additionalServicesByType]);
    const isCanModifyServices = useMemo(() => {
        var _a, _b;
        if (!((_b = (_a = additionalServices.gdsServices) === null || _a === void 0 ? void 0 : _a.services) === null || _b === void 0 ? void 0 : _b.length)) {
            return false;
        }
        return additionalServices.gdsServices.services.some(service => canBeModifyService(service, travellers));
    }, [additionalServices, travellers]);
    const showService = useMemo(() => {
        return getServiceVisible(type, order, additionalServicesByType, isCanModifyServices, {
            includedBaggageAvailable: brandIncludedBaggage.length > 0,
            animalAvailable: servicesAvailable.animal
        });
    }, [type, order, additionalServicesByType, brandIncludedBaggage, servicesAvailable.animal, isCanModifyServices]);
    // Methods
    const onSaveServicesRequest = useCallback((serviceIds) => __awaiter(this, void 0, void 0, function* () {
        const params = { id: order.id };
        if (type === 'insurance') {
            params.insurances = [
                {
                    selectedPrograms: serviceIds,
                    selectedProgramsByTravellers: travellers.map(traveller => ({
                        travellerId: traveller.id,
                        travellerDocumentNumber: '',
                        programCodes: serviceIds
                    }))
                }
            ];
        }
        else if (type === 'transfer') {
            params.aeroexpress = { selectedTrips: serviceIds };
        }
        return orderServices.onSaveServicesRequest(params);
    }), [type, order.id, travellers, additionalServices, orderServices.onSaveServicesRequest]);
    const onAddServices = useCallback((servicesToAdd) => __awaiter(this, void 0, void 0, function* () {
        var _a, _b, _c, _d, _e;
        if (type === 'insurance' || type === 'transfer') {
            const serviceIds = new Set();
            if (type === 'insurance') {
                const insuranceServices = servicesToAdd;
                (_b = (_a = additionalServices.insurances) === null || _a === void 0 ? void 0 : _a.selectedInsurances) === null || _b === void 0 ? void 0 : _b.forEach(insurance => serviceIds.add(insurance.insuranceProgram.code));
                insuranceServices.forEach(service => serviceIds.add(service.code));
            }
            else if (type === 'transfer') {
                const transferServices = servicesToAdd;
                (_e = (_d = (_c = additionalServices.transfer) === null || _c === void 0 ? void 0 : _c.aeroexpress) === null || _d === void 0 ? void 0 : _d.selectedTrips) === null || _e === void 0 ? void 0 : _e.forEach(trip => serviceIds.add(trip.id));
                transferServices.forEach(service => serviceIds.add(service.id));
            }
            yield onSaveServicesRequest([...serviceIds]);
        }
        else {
            const gdsServices = servicesToAdd;
            const servicesPayload = gdsServices.map(service => extraGdsServiceToServicePayload(service, gdsServiceType));
            orderServices.onAddServices(servicesPayload);
        }
    }), [type, gdsServiceType, onSaveServicesRequest, orderServices.onAddServices]);
    const onRemoveServices = useCallback((servicesToRemove) => __awaiter(this, void 0, void 0, function* () {
        var _f, _g, _h, _j, _k;
        if (type === 'insurance' || type === 'transfer') {
            const serviceIds = new Set();
            if (type === 'insurance') {
                const insuranceServices = servicesToRemove;
                (_g = (_f = additionalServices.insurances) === null || _f === void 0 ? void 0 : _f.selectedInsurances) === null || _g === void 0 ? void 0 : _g.forEach(insurance => serviceIds.add(insurance.insuranceProgram.code));
                insuranceServices.forEach(service => serviceIds.delete(service.code));
            }
            else if (type === 'transfer') {
                const transferServices = servicesToRemove;
                (_k = (_j = (_h = additionalServices.transfer) === null || _h === void 0 ? void 0 : _h.aeroexpress) === null || _j === void 0 ? void 0 : _j.selectedTrips) === null || _k === void 0 ? void 0 : _k.forEach(trip => serviceIds.add(trip.id));
                transferServices.forEach(service => serviceIds.delete(service.id));
            }
            yield onSaveServicesRequest([...serviceIds]);
        }
        else {
            const gdsServices = servicesToRemove;
            const servicesPayload = gdsServices.map(service => extraGdsServiceToServicePayload(service, gdsServiceType, 0));
            orderServices.onRemoveServices(servicesPayload);
        }
    }), [type, gdsServiceType, additionalServices, onSaveServicesRequest, orderServices.onRemoveServices]);
    const onRemoveUnconfirmedServices = useCallback(() => orderServices.onRemoveServices(unconfirmedServices), [
        unconfirmedServices,
        orderServices.onRemoveServices
    ]);
    return {
        // data
        order,
        flight,
        travellers,
        additionalServices,
        additionalServicesByType,
        unconfirmedServices,
        selectedServices,
        gdsServiceType,
        requestError: orderServices.requestError,
        isSingleService,
        isCanModifyServices,
        isLoading: orderServices.isLoading,
        showService,
        // methods
        onClearError: orderServices.onClearError,
        onSaveServicesRequest,
        onAddServices,
        onRemoveServices,
        onRemoveUnconfirmedServices,
        onRemoveService: cart.onDeleteItem
    };
}
export function useCardExtraPrices({ type, insuranceCodes, additionalServices }) {
    // Selectors
    const travellers = useSelector(getTravellers);
    const flight = useSelector(getFlight);
    const isAllSegmentsAvailableOnCheckin = useSelector(getIsAllSegmentsAvailableOnlyOnCheckin);
    // Hooks
    const prices = useCartPrices();
    // Data
    const isDifferentPrices = useMemo(() => {
        const servicesPrices = getServicesPrices(type, additionalServices);
        return new Set(servicesPrices.map(price => price.amount)).size > 1;
    }, [type, additionalServices]);
    const passengerPrices = useMemo(() => {
        return getPassengerPrices(type, additionalServices, travellers, isDifferentPrices);
    }, [type, additionalServices, travellers, isDifferentPrices]);
    const totalPrice = useMemo(() => {
        var _a, _b;
        if (type === 'insurance' && insuranceCodes.length) {
            const selectedInsuranceMap = new Map();
            if ((_b = (_a = additionalServices.insurances) === null || _a === void 0 ? void 0 : _a.selectedInsurances) === null || _b === void 0 ? void 0 : _b.length) {
                additionalServices.insurances.selectedInsurances.forEach(insurance => {
                    const insuranceCode = insurance.insuranceProgram.code;
                    if (insuranceCodes.includes(insuranceCode) && !selectedInsuranceMap.has(insuranceCode)) {
                        selectedInsuranceMap.set(insuranceCode, insurance);
                    }
                });
            }
            return getTotalPrice([...selectedInsuranceMap.values()], insurance => insurance.insuranceProgram.totalPrice);
        }
        const priceKey = getCartPriceKeyByType(type);
        if (!priceKey) {
            return null;
        }
        return prices[priceKey];
    }, [type, additionalServices, prices]);
    const minPrice = useMemo(() => {
        var _a, _b, _c;
        if (type === 'seats') {
            if (isAllSegmentsAvailableOnCheckin) {
                return null;
            }
            const seatPricesMap = new Map();
            if ((_b = (_a = flight.seatMap) === null || _a === void 0 ? void 0 : _a.segments) === null || _b === void 0 ? void 0 : _b.length) {
                flight.seatMap.segments.forEach(segment => {
                    var _a;
                    (_a = segment.decks) === null || _a === void 0 ? void 0 : _a.forEach(deck => {
                        var _a;
                        (_a = deck.rows) === null || _a === void 0 ? void 0 : _a.forEach(row => {
                            var _a;
                            (_a = row.seats) === null || _a === void 0 ? void 0 : _a.forEach(seat => {
                                const price = seat === null || seat === void 0 ? void 0 : seat.price;
                                const amount = price === null || price === void 0 ? void 0 : price.amount;
                                if ((seat === null || seat === void 0 ? void 0 : seat.isAvailable) && amount !== undefined && !seatPricesMap.has(amount)) {
                                    seatPricesMap.set(amount, price);
                                }
                            });
                        });
                    });
                });
            }
            return getMinPrice([...seatPricesMap.values()], price => price);
        }
        if (type === 'sms') {
            return getAllSegmentsServicePrice((_c = additionalServices.gdsServices) === null || _c === void 0 ? void 0 : _c.services[0]);
        }
        return getMinPrice(passengerPrices, price => price.price);
    }, [type, passengerPrices, flight, isAllSegmentsAvailableOnCheckin]);
    return {
        isDifferentPrices,
        passengerPrices,
        totalPrice,
        minPrice
    };
}
export function useCardExtraDisclosure(type) {
    const [openServiceType, setOpenServiceType] = useState();
    const onToggleModal = useCallback(() => {
        setOpenServiceType(state => (state ? undefined : type));
    }, [type, setOpenServiceType]);
    const onOpenModal = useCallback(() => {
        setOpenServiceType(type);
    }, [type, setOpenServiceType]);
    const onCloseModal = useCallback(() => {
        setOpenServiceType(undefined);
    }, [setOpenServiceType]);
    return {
        // data
        openServiceType,
        isOpenModal: !!openServiceType,
        // methods
        setOpenServiceType,
        onToggleModal,
        onOpenModal,
        onCloseModal
    };
}
export function useCardExtraIconSource(type) {
    const { global: { companyInfo: { iataCode } } } = useConfig();
    return {
        src: `https://cdn.websky.aero/content/frontend/images/${iataCode}/CardExtra/${type}.svg`,
        fallbackSrc: `https://cdn.websky.aero/content/frontend/images/ZZ/CardExtra/${type}.svg`
    };
}
