import { FareLockIcon } from './components/FareLockIcon/FareLockIcon';
import { Logo } from './theme/images/Images';
import FareGroupGridRulesButton from './components/FareGroupGridRulesButton/FareGroupGridRulesButton';
import FlightInfo from './components/FlightInfo/FlightInfo';
import SearchFormDatePicker from './components/SearchFormDatePicker/SearchFormDatePicker';
import SeatMap from './components/SeatMap/SeatMap';
import Seat from './components/SeatMap/Seat/Seat';
import SeatMapAdditionalDisclaimer from './components/SeatMapAdditionalDisclaimer/SeatMapAdditionalDisclaimer';
import QuickSearchForm from './components/QuickSearchForm/QuickSearchForm';
import FastSearch from './components/FastSearch/FastSearch';
import CheckoutAdditionalService from './components/CheckoutAdditionalService/CheckoutAdditionalService';
export var renders = {
    RenderFareLockIcon: function () { return FareLockIcon; },
    renderFlightInfo: FlightInfo,
    MetasearchLoaderLogo: Logo,
    FareGroupGridRulesButton: FareGroupGridRulesButton,
    SearchFormDatePicker: SearchFormDatePicker,
    SeatMapService: SeatMap,
    SeatMapSeat: Seat,
    seatsInfoAdditionalSlot: SeatMapAdditionalDisclaimer,
    QuickSearchForm: QuickSearchForm,
    FastSearch: FastSearch,
    CheckoutAdditionalService: { CheckoutAdditionalService: CheckoutAdditionalService }
};
