import { __assign } from "tslib";
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { initI18n } from '@websky/core/src/utils';
import SearchFormDatepicker from '@websky/core/src/SearchFormDatepicker/components/SearchFormDatepicker';
initI18n('SearchFormDatepicker');
var SearchFormDatePicker = function (props) {
    var t = useTranslation('SearchFormDatepicker').t;
    var formatPrice = function (value) {
        var formattedPrice = value;
        if (value.length > 6) {
            formattedPrice = value.slice(0, 3) + t('k.');
        }
        return formattedPrice;
    };
    return React.createElement(SearchFormDatepicker, __assign({}, props, { formatPrice: formatPrice }));
};
export default SearchFormDatePicker;
