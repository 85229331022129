import * as React from 'react';
export var owIcon = (React.createElement("svg", { width: "14", height: "14", viewBox: "0 0 14 14", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M11.1488 7.93343H0V6.76676H11.1594L7.55095 3.15833L8.37591 2.33337L12.8093 6.76676H12.8333V6.7908L13.3873 7.3448L12.8333 7.8988V7.93343H12.7987L8.37591 12.3562L7.55095 11.5313L11.1488 7.93343Z", fill: "currentColor" })));
export var rtIcon = (React.createElement("svg", { width: "14", height: "14", viewBox: "0 0 14 14", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("g", { clipPath: "url(#clip0)" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M-4.48227e-05 4.43019H11.1488L8.72062 6.85836L9.54558 7.68331L12.7987 4.43019H12.8333V4.39558L13.3873 3.84156L12.8333 3.28754V3.26353H12.8093L9.5456 -0.000244141L8.72064 0.824714L11.1594 3.26353H-4.48227e-05V4.43019ZM13.3873 10.2635H2.23848L4.66661 12.6917L3.84166 13.5167L0.588562 10.2635H0.553951V10.2289L-6.10352e-05 9.6749L0.553951 9.12087V9.09686H0.577961L3.84164 5.83309L4.6666 6.65805L2.22787 9.09686H13.3873V10.2635Z", fill: "currentColor" })),
    React.createElement("defs", null,
        React.createElement("clipPath", { id: "clip0" },
            React.createElement("rect", { width: "14", height: "14", fill: "currentColor" })))));
export var passengerIcon = (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M12 12C13.66 12 15 10.66 15 9C15 7.34 13.66 6 12 6C10.34 6 9 7.34 9 9C9 10.66 10.34 12 12 12ZM12 13.9C10 13.9 6 15 6 17V18H18V17C18 15 14 13.9 12 13.9Z", fill: "currentColor" })));
export var arrowIcon = (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { d: "M18 10.41L16.59 9L12 13.58L7.41 9L6 10.41L12 16.41L18 10.41Z", fill: "currentColor" })));
