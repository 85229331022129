import cn from 'classnames';
import Checkout from './components/Checkout/Checkout.css';
import PaymentForm from './components/PaymentForm/PaymentForm.css';
import CheckoutPassengersToolbar from './components/Checkout/Form/PassengersToolbar/PassengersToolbar.css';
import Notification from './components/Notification/Notification.css';
import PassengerFormHeader from './components/PassengerForm/Header/Header.css';
import PassengerFormGroup from './components/PassengerForm/FormFields/Group/Group.css';
import PassengerFormLoyaltyGroup from './components/PassengerForm/FormFields/Group/LoyaltyGroup/LoyaltyGroup.css';
import PassengerSelect from './components/Passenger/Field/Select/Select.css';
import PassengerSwitch from './components/Passenger/Field/Switch/Switch.css';
import Contacts from './components/Contacts/Contacts.css';
import MobileAuthForm from './components/MobileAuthForm/MobileAuthForm.css';
import MobileToolbar from './components/MobileToolbar/MobileToolbar.css';
import * as SeatMap from './components/SeatMap/SeatMap.css';
import * as SegmentTabs from './components/SegmentTabs/SegmentTabs.css';
import * as MealService from './components/MealService/MealService.css';
import * as Meal from './components/Meal/Meal.css';
import * as Counter from './components/Counter/Counter.css';
import * as ServiceRecommendation from './components/ServiceRecommendation/ServiceRecommendation.css';
import * as VisaForm from './components/VisaForm/VisaForm.css';
import * as Stepbar from './components/Stepbar/Stepbar.css';
import * as Results from './components/Results/Results.css';
import * as WeekCalendar from './components/WeekCalendar/WeekCalendar.css';
import * as DesktopFlightRoute from './components/DesktopFlightRoute/DesktopFlightRoute.css';
import * as Amenities from './components/Amenities/Amenities.css';
import * as QuickSearchForm from './components/QuickSearchForm/QuickSearchForm.css';
import * as FareLock from './components/FareLock/FareLock.css';
import * as Toolbar from './components/Toolbar/Toolbar.css';
import * as DesktopFlight from './components/DesktopFlight/DesktopFlight.css';
import * as FlightCard from './components/FlightCard/FlightCard.css';
import * as FareGroup from './components/FareGroup/FareGroup.css';
import * as CompareFares from './components/CompareFares/CompareFares.css';
import * as FareConditions from './components/FareConditions/FareConditions.css';
import * as Modal from './components/Modal/Modal.css';
import * as Switch from './components/Switch/Switch.css';
import * as MobileFlight from './components/MobileFlight/MobileFlight.css';
import * as MobileSelectedFare from './components/MobileSelectedFare/MobileSelectedFare.css';
import AdditionalServiceBanner from './components/AdditionalServiceBanner/AdditionalServiceBanner.css';
import IncludedBaggage from './components/IncludedBaggage/IncludedBaggage.css';
import AdditionalBaggage from './components/AdditionalBaggage/AdditionalBaggage.css';
import BaggageList from './components/BaggageList/BaggageList.css';
import Baggage from './components/Baggage/Baggage.css';
import BaggageCard from './components/BaggageCard/BaggageCard.css';
import BaggageTotalPriceBlock from './components/BaggageTotalPriceBlock/BaggageTotalPriceBlock.css';
import UpgradeBaggageDialog from './components/UpgradeBaggageDialog/UpgradeBaggageDialog.css';
import BaggageCounter from './components/BaggageCounter/BaggageCounter.css';
import OverweightBaggage from './components/OverweightBaggage/OverweightBaggage.css';
import SpecialEquipment from './components/SpecialEquipment/SpecialEquipment.css';
import PassengerDetails from './components/Checkout/PassengerDetails/PassengerDetails.css';
import Forms from './components/Forms/Forms.css';
import * as SearchForm from './components/SearchForm/SearchForm.css';
import * as Refund from './components/Refund/Refund.css';
import * as Exchange from './components/Exchange/Exchange.css';
import * as OrderCard from './components/OrderCard/OrderCard.css';
import * as Insurance from './components/Insurance/Insurance.css';
import * as LoginPage from './components/LoginPage/LoginPage.css';
import * as Warnings from './components/Warnings/Warnings.css';
import * as Account from './components/Account/Account.css';
import * as DocumentForm from './components/DocumentForm/DocumentForm.css';
import * as Checkin from './components/Checkin/Checkin.css';
import InfoField from './components/Account/InfoField/InfoField.css';
import ServiceSummary from './components/ServiceSummary/ServiceSummary.css';
import PaymentResult from './components/PaymentResult/PaymentResult.css';
import BaggagePage from './components/BaggagePage/BaggagePage.css';
import VipServiceSelect from './components/VipServiceSelect/VipServiceSelect.css';
import PriceGraph from './components/PriceGraph/PriceGraph.css';
import LocationAutocomplete from './components/LocationAutocomplete/LocationAutocomplete.css';
import MobileStepbar from './components/MobileStepbar/MobileStepbar.css';
import * as PromoLoader from './components/PromoLoader/PromoLoader.css';
var theme = {
    Stepbar: {
        Stepbar: {
            stepbar: Stepbar.stepbar__container
        },
        Step: {
            id: Stepbar.id,
            step: Stepbar.step__step,
            step_active: Stepbar.step__stepActive,
            step_disabled: Stepbar.step__stepDisabled,
            arrow: Stepbar.step__arrow,
            step_passed: Stepbar.step__step_passed
        }
    },
    MobileToolbar: {
        MobileToolbar: {
            wrapper: MobileToolbar.wrapper,
            button_continue: MobileToolbar.button_continue,
            button_back: MobileToolbar.button_back
        }
    },
    ServiceSummary: {
        ServiceSummary: {
            header__icon: ServiceSummary.header__icon,
            header__number: ServiceSummary.header__number
        },
        ServiceSummaryOption: {
            option__included: ServiceSummary.option__included,
            option__price_money: ServiceSummary.option__money
        }
    },
    Forms: {
        Forms: {
            tabs: Forms.tabs,
            tabs__container: Forms.tabs__container,
            tabSelected: Forms.tabSelected,
            tabLabel: Forms.tabLabel,
            indicator: Forms.indicator,
            container: Forms.container
        },
        Booking: {
            container: Forms.booking,
            submit: Forms.submit,
            inner: Forms.booking__inner
        },
        Field: {
            input__inner: Forms.input,
            input__wrapper: Forms.input__wrapper,
            label: Forms.label,
            label_shrink: Forms.label_shrink,
            label_error: Forms.label_error
        }
    },
    Tabs: {
        Tabs: {
            desc: cn(Forms.tabs__desc)
        }
    },
    Results: {
        Results: {
            header: Results.header,
            results: Results.results__container,
            inner: Results.results__inner,
            toolbar__backButton: Results.results__toolbarBackButton,
            toolbar__nextButton: Results.results__toolbarNextButton
        },
        Sortings: {
            paper: Results.paper
        },
        Calendar: {
            legNumber: Results.calendar__legNumber,
            title__text: Results.calendar__titleText,
            tabs: Results.calendar__tabs,
            diagram: Results.calendar__diagram
        },
        SelectedFares: {
            title: Results.selectedFares__title
        },
        Disclaimer: {
            wrapper: Results.disclaimer__wrapper
        },
        OtherDates: {
            container: Results.otherDates__container,
            text: Results.otherDates__text
        },
        MobilePriceGraph: {
            dates: Results.mobilePriceGraph__dates
        }
    },
    PriceGraph: {
        PriceGraphStyles: {
            container: PriceGraph.container,
            graphHeader: PriceGraph.graphHeader,
            button: PriceGraph.button
        },
        DayStyles: {
            day: PriceGraph.day__day,
            outDate: PriceGraph.day__outDate,
            backDate: PriceGraph.day__backDate,
            tooltip: PriceGraph.day__tooltip,
            progress: PriceGraph.progress,
            day_focused: PriceGraph.day_focused,
            day_notAvailable: PriceGraph.day_notAvailable
        }
    },
    WeekCalendar: {
        WeekCalendarStyles: {
            dayWrapper: WeekCalendar.weekCalendar__dayWrapper
        },
        DayStyles: {
            day: WeekCalendar.day__day,
            day_notAvailable: WeekCalendar.day__dayNotAvailable,
            day_selected: WeekCalendar.day__daySelected,
            date: WeekCalendar.day__date,
            date__info: WeekCalendar.day__dateInfo,
            price__wrapper: WeekCalendar.day__priceWrapper,
            price: WeekCalendar.day__price,
            day_best: WeekCalendar.day__dayBest
        },
        SliderStyles: {
            arrow: WeekCalendar.slider__arrow
        }
    },
    DesktopFlightRoute: {
        DesktopFlightRouteStyles: {
            flight: DesktopFlightRoute.desktopFlight__container,
            selected: DesktopFlightRoute.desktopFlight__selected
        },
        SegmentStyles: {
            route__info: DesktopFlightRoute.segment__routeInfo,
            flightInfo__container: DesktopFlightRoute.segment__flightInfoContainer,
            flightInfo__icon: DesktopFlightRoute.segment__flightInfoIcon,
            flightInfo__containerAirline: DesktopFlightRoute.segment__flightInfoContainerAirline,
            flightInfo__airline: DesktopFlightRoute.segment__flightInfoAirline,
            flightInfo__date: DesktopFlightRoute.segment__flightInfoDate,
            aircraft: DesktopFlightRoute.segment__aircraft,
            segment: DesktopFlightRoute.segment__segment,
            segment_additional: DesktopFlightRoute.segment__segmentAdditional,
            flightInfo__nextDay: DesktopFlightRoute.segment__flightInfoNextDay,
            totalDuration: DesktopFlightRoute.segment__totalDuration
        },
        PointStyles: {
            time: DesktopFlightRoute.point__time,
            airport: DesktopFlightRoute.point__airport,
            date__container: DesktopFlightRoute.point__dateContainer,
            point_thin: DesktopFlightRoute.point__thin
        },
        RouteArrowStyles: {
            container: DesktopFlightRoute.routeArrow__container,
            route: DesktopFlightRoute.routeArrow__route,
            timeInRoute: DesktopFlightRoute.routeArrow__timeInRoute,
            circle: DesktopFlightRoute.routeArrow__circle,
            flightNumber: DesktopFlightRoute.routeArrow__flightNumber
        },
        FlightPriceStyles: {
            price: DesktopFlightRoute.flightPrice__price,
            best: DesktopFlightRoute.flightPrice__best,
            selectFrom_best: DesktopFlightRoute.flightPrice__selectFromBest,
            button: DesktopFlightRoute.flightPrice__button,
            fare_family: DesktopFlightRoute.flightPrice__fareFamily,
            price_selected: DesktopFlightRoute.flightPrice__priceSelected,
            price__money: DesktopFlightRoute.flightPrice__pricesMoney,
            clear: DesktopFlightRoute.flightPrice__clear
        },
        StopsInfoStyles: {
            stop__label: DesktopFlightRoute.stopsInfo__stopLabel
        }
    },
    Amenities: {
        Amenities: {
            amenities__item: Amenities.amenities__amenitiesItem,
            amenities__item_unavailable: Amenities.amenities__amenitiesItemUnavailable
        }
    },
    QuickSearchForm: {
        QuickSearchForm: {
            wrapper: QuickSearchForm.quickSearchForm__wrapper,
            inner: QuickSearchForm.quickSearchForm__inner,
            form__wrapper: QuickSearchForm.quickSearchForm__formWrapper
        },
        Summary: {
            route: QuickSearchForm.summary__route,
            passengers: QuickSearchForm.summary__passengers,
            trigger: QuickSearchForm.summary__trigger,
            route__icon: QuickSearchForm.summary__routeIcon,
            trigger__arrow: QuickSearchForm.summary__triggerArrow,
            summary: QuickSearchForm.summary
        }
    },
    PaymentForm: {
        PaymentForm: {
            card: PaymentForm.card,
            details__button: Checkout.paymentForm__detailsButton,
            details: Checkout.paymentForm__details
        }
    },
    FareLock: {
        FareLock: {
            root: FareLock.fareLock__root
        },
        SwitchBlock: {
            root: FareLock.switchBlock__root
        },
        DescriptionBlock: {
            title: FareLock.descriptionBlock__title,
            description: FareLock.descriptionBlock__description
        }
    },
    Toolbar: {
        Toolbar: {
            toolbar: Toolbar.toolbar__container,
            price: Toolbar.toolbar__price,
            miles: Toolbar.toolbar__miles,
            miles__hint: Toolbar.toolbar__milesHint,
            actions: Toolbar.toolbar__actions,
            inner: Toolbar.toolbar__inner
        }
    },
    DesktopFlight: {
        ModalContent: {
            header: DesktopFlight.modalContent__header,
            header_title: DesktopFlight.modalContent__headerTitle,
            close: DesktopFlight.modalContent__close,
            close_icon: DesktopFlight.modalContent__closeIcon,
            fareGroup__button: DesktopFlight.modalContent__fareGroupButton,
            fareGroup__button_active: DesktopFlight.modalContent__fareGroupButtonActive,
            compareFaresLink: DesktopFlight.modalContent__compareFaresLink,
            compareFaresLink_icon: DesktopFlight.modalContent__compareFaresLinkIcon,
            pagination__index: DesktopFlight.modalContent__paginationIndex,
            paper: DesktopFlight.modalContent__paper,
            scroll_up: DesktopFlight.modalContent__scrollUp,
            total_time: DesktopFlight.modalContent__totalTime,
            header_arrow: DesktopFlight.modalContent__headerArrow,
            slider: DesktopFlight.modalContent__slider
        },
        FlightInfoSegment: {
            transfer: DesktopFlight.flightInfo__transfer
        },
        Location: {
            time: DesktopFlight.location__time,
            time_iata: DesktopFlight.location__timeIata,
            date: DesktopFlight.location__date,
            date_icon: DesktopFlight.location__dateIcon,
            city: DesktopFlight.location__city,
            terminal: DesktopFlight.location__terminal,
            weather_temp: DesktopFlight.location__weatherTemp
        }
    },
    FlightCard: {
        FlightCard: {
            airline__logo: FlightCard.flightCard__airlineLogo,
            airline__number: FlightCard.flightCard__airlineNumber,
            airline__name: FlightCard.flightCard__airlineName,
            flight__block: FlightCard.flightCard__flightBlock,
            flight__title: FlightCard.flightCard__flightTitle,
            flight__value: FlightCard.flightCard__flightValue,
            aircraft__name: FlightCard.flightCard__aircraftName,
            info: FlightCard.flightCard__info,
            airline: FlightCard.flightCard__airline
        }
    },
    FareGroup: {
        FareGroup: {
            fareGroup_white: FareGroup.fareGroup__fareGroupWhite,
            fareGroup: FareGroup.fareGroup__fareGroup,
            option_unavailable: FareGroup.fareGroup__optionUnavailable,
            option__icon: FareGroup.fareGroup__optionIcon,
            option__title: FareGroup.fareGroup__optionTitle,
            option__value: FareGroup.fareGroup__optionValue,
            upgradeOffer: FareGroup.fareGroup__upgradeOffer
        },
        FareGroupGrid: {
            option: FareGroup.fareGroupGrid__option,
            paid: FareGroup.fareGroupGrid__paid,
            icon: FareGroup.fareGroupGrid__icon,
            recommended: FareGroup.fareGroupGrid__recommended
        },
        RecommendedBadge: {
            badge: FareGroup.recommendedBadge__badge
        }
    },
    CompareFares: {
        CompareFares: {
            compareFares: CompareFares.compareFares__container,
            modal__heading: CompareFares.compareFares__modalHeading,
            groupTitle: CompareFares.compareFares__groupTitle,
            tableRow: CompareFares.compareFares__tableRow,
            title: CompareFares.compareFares__title,
            cell: CompareFares.compareFares__cell,
            availabilityIcon: CompareFares.compareFares__availabilityIcon,
            slider: CompareFares.compareFares__slider,
            fareHeadingItem: CompareFares.compareFares__fareHeadingItem,
            fareHeadingItem_isKey: CompareFares.compareFares__fareHeadingItemIsKey,
            link: CompareFares.compareFares__link,
            content: CompareFares.compareFares__content,
            linkIcon_return: CompareFares.compareFares__linkIcon_return,
            slider_withoutImages: CompareFares.compareFares__slider_withoutImages,
            cellContent: CompareFares.compareFares__cellContent
        }
    },
    FareConditions: {
        FareConditions: {
            modal__heading: FareConditions.fareConditions__modalHeading,
            tabsWrp: FareConditions.fareConditions__tabsWrapper,
            segmentTabs: FareConditions.fareConditions__segmentTabs,
            fareConditionsSegmentHeading: FareConditions.fareConditions__fareConditionsSegmentHeading,
            fareConditionsSegmentFareCode: FareConditions.fareConditions__fareConditionsSegmentFareCode,
            fareConditionsContent: FareConditions.fareConditions__fareConditionsContent,
            tab: FareConditions.fareConditions__tab,
            tab_active: FareConditions.fareConditions__tabActive,
            tabIndicator: FareConditions.fareConditions__tabIndicator
        }
    },
    Modal: {
        Modal: {
            modal_rounded: Modal.modal__modalRounded,
            closeIcon: Modal.modal__closeIcon
        }
    },
    Switch: {
        Switch: {
            wrapper: Switch.switch__wrapper,
            active: Switch.switch__active
        }
    },
    MobileFlight: {
        FlightStyles: {
            flight: MobileFlight.flight__container,
            flight_selected: MobileFlight.flight__containerSelected,
            segments: MobileFlight.flight__segments,
            price: MobileFlight.flight__price,
            stopPoint: MobileFlight.flight__stopPoint,
            selectedFare: MobileFlight.flight_selectedFare,
            price__close: MobileFlight.flight__priceClose,
            selectedFare__priceWrapper: MobileFlight.flight__selectedFarePriceWrapper,
            selectedFare__price: MobileFlight.flight__selectedFarePrice
        }
    },
    MobileSelectedFare: {
        MobileSelectedFareStyles: {
            features__title: MobileSelectedFare.mobileSelectedFare__featureTitle,
            feature__text: MobileSelectedFare.mobileSelectedFare__featureText,
            features__info: MobileSelectedFare.mobileSelectedFare__featuresInfo,
            feature__icon: MobileSelectedFare.mobileSelectedFare__featureIcon,
            dialog__header: MobileSelectedFare.mobileSelectedFare__dialogHeader
        }
    },
    Button: {
        Button: {
            button: cn(Contacts.button, Account.rmButton)
        },
        ActionButton: {
            button: Checkout.actionButton
        }
    },
    Checkout: {
        CheckoutStyles: {
            container: Checkout.checkout__container,
            gdsTotalPrice: Checkout.checkout__gdsTotalPrice,
            summary__wrp: Checkout.summary__wrp,
            summary__header: Checkout.summary__header,
            messages: Checkout.messages,
            messages_item: Checkout.messages_item,
            desktopFlight__flight: Checkout.desktopFlight__flight,
            form: cn(Checkout.form, PassengerSwitch.form)
        },
        // PassengersStyles: {
        // 	passengers: CheckoutPassengers.passengers
        // },
        PassengersToolbar: {
            checkbox: CheckoutPassengersToolbar.checkbox,
            checkbox_checked: CheckoutPassengersToolbar.checkbox_checked,
            label: CheckoutPassengersToolbar.label
        },
        Stepbar: {
            additional: Stepbar.step__additional
        }
    },
    PassengersDetails: {
        Passenger: {
            passenger: PassengerDetails.passenger,
            filled: PassengerDetails.passenger_filled,
            number: PassengerDetails.number,
            name: PassengerDetails.header
        }
    },
    Contacts: {
        ContactsStyles: {
            footer: Contacts.footer,
            footer_login: Contacts.footer_login,
            footer__text: Contacts.footer__text,
            footer__inner: Contacts.footer__inner,
            description__header: Contacts.description__header
        }
    },
    Input: {
        Input: {
            input: cn(Checkout.input, Account.rmInput, LoginPage.rmInput, Checkin.rmInput),
            input_filled: cn(LoginPage.rmInput_filled, Checkin.rmInput_filled),
            input_focused: cn(Checkout.input_focused, LoginPage.rmInput_focused, Checkin.rmInput_focused),
            input_error: cn(Checkout.input_error),
            real_input: cn(Checkout.real_input, Account.rmInput__real, LoginPage.rmInput__real, Checkin.rmInput__real),
            textField__root: cn(Checkout.textField__root, Account.rmInput__textField, LoginPage.rmInput__textField, Checkin.rmInput__textField),
            textField_focused: cn(Checkout.textField_focused),
            textField_standard: cn(Checkout.textField_standard),
            textField_filled: cn(Checkout.textField_filled),
            label_error: cn(Checkout.label_error),
            label_shrink: cn(Checkout.label_shrink, Account.rmInput__labelShrink, LoginPage.rmInput__labelShrink, Checkin.rmInput__labelShrink),
            hint_error: cn(Checkout.hint_error),
            hint: cn(Account.rmInput__hint),
            underline: cn(Checkout.underline)
        }
    },
    Link: {
        Link: {
            link: cn(CheckoutPassengersToolbar.link)
        }
    },
    MobileAuthForm: {
        MobileAuthForm: {
            title: MobileAuthForm.title,
            sub: MobileAuthForm.sub,
            email: MobileAuthForm.email,
            button: MobileAuthForm.button
        }
    },
    Notification: {
        Notification: {
            context: Notification.context,
            info: Notification.info,
            icon: Notification.icon,
            title: Notification.title,
            text: Notification.text,
            warning: Notification.warning,
            action: Notification.action
        }
    },
    PassengerForm: {
        Header: {
            number: PassengerFormHeader.number,
            passengerName: PassengerFormHeader.passengerName
        },
        Group: {
            container: PassengerFormGroup.container,
            contacts: PassengerFormGroup.contacts,
            field_birthdate: PassengerFormGroup.field_birthdate,
            field_sex: PassengerFormGroup.field_sex,
            identity: PassengerFormGroup.identity
        },
        LoyaltyGroup: {
            loyalty_switch: PassengerFormLoyaltyGroup.loyalty_switch,
            loyalty_switch_open: PassengerFormLoyaltyGroup.loyalty_switch_open,
            toggle: PassengerFormLoyaltyGroup.toggle,
            switch__label: PassengerFormLoyaltyGroup.switch__label,
            loyalty__desc: PassengerFormLoyaltyGroup.loyalty__desc
        }
    },
    Passenger: {
        Switch: {
            element: PassengerSwitch.element,
            element_active: PassengerSwitch.element_active,
            element_error: PassengerSwitch.element_error,
            error: PassengerSwitch.error
        },
        Select: {
            helperText: PassengerSelect.helperText,
            option: PassengerSelect.option
        }
    },
    SeatMap: {
        AirplaneStyles: {
            airplane__name: SeatMap.airplane__airplaneName,
            details: SeatMap.airplane__details,
            seatsDetails: SeatMap.airplane__seatsDetails,
            segment: SeatMap.airplane__segment
        },
        AirplaneInfoStyles: {
            sunInfo: SeatMap.airplaneInfo__sunInfo
        },
        Passenger: {
            item: SeatMap.passenger__item,
            item_active: SeatMap.passenger__itemActive,
            item_recommend: SeatMap.passenger__itemRecommend,
            avatar: SeatMap.passenger__avatar,
            passengerName: SeatMap.passenger__passengerName,
            payload: SeatMap.passenger__payload,
            money: SeatMap.passenger__money,
            icon: SeatMap.passenger__icon,
            select: SeatMap.passenger__select,
            select_selected: SeatMap.passenger__selectSelected,
            item_confirmed: SeatMap.passenger__itemConfirmed,
            clear: SeatMap.passenger__seatClear,
            selectMobile: SeatMap.passenger__selectMobile,
            selectMobile_selected: SeatMap.passenger__selectMobile_selected
        },
        PassengersStyles: {
            recommend: SeatMap.passengers__recommend,
            recommend__icon: SeatMap.passengers__recommendIcon
        },
        Controls: {
            button: SeatMap.controls__button
        },
        SeatMapDisclaimerStyles: {
            disclaimer: SeatMap.disclaimer__container
        },
        SeatsInfoStyles: {
            classInfo__payload: SeatMap.seatsInfo__classInfoPayload,
            title: SeatMap.seatsInfo__title,
            description: SeatMap.seatsInfo__description,
            seat__info: SeatMap.seatsInfo__seatInfo,
            feature: SeatMap.seatMap__feature,
            price__wrapper: SeatMap.seatMap__priceWrapper,
            price: SeatMap.seatMap__price,
            seat_preferred: SeatMap.seatsInfo__seatPreferred,
            seat__icon: SeatMap.seatsInfo__seatIcon
        },
        SeatMapStyles: {
            container: SeatMap.seatMap__container,
            wings: SeatMap.seatMap__wings,
            row__serviceHeader: SeatMap.seatMap__rowServiceHeader,
            row__serviceHeader__wrapper: SeatMap.seatMap__rowServiceHeaderWrapper,
            row_serviceHeader_from: SeatMap.seatMap__rowServiceHeaderFrom,
            row_serviceHeader_from_after: SeatMap.row_serviceHeader_from_after,
            exit__label: SeatMap.seatMap__exitLabel
        },
        SeatStyles: {
            seat: SeatMap.seat__seat,
            number: SeatMap.seat__number,
            seat_free: SeatMap.seat_free,
            seat_business: SeatMap.seat__seatBusiness,
            seat_occupied: SeatMap.seat__seatOccupied,
            seat_preferred: SeatMap.seat__seatPreferred,
            seat_unavailable: SeatMap.seat__seatUnavailable,
            seat_unexistent: SeatMap.seat__seatUnexistent,
            seat_recommend: SeatMap.seat__seatRecommend,
            seat_recommendCurrent: SeatMap.seat__seatRecommendCurrent
        },
        SeatsRecommendPopup: {
            main: SeatMap.seatRecommendPopup__main,
            main__icon: SeatMap.seatRecommendPopup__mainIcon,
            price__wrapper: SeatMap.seatRecommendPopup__priceWrapper,
            money: SeatMap.seatRecommendPopup__money
        },
        SeatTooltipStyles: {
            tooltip: SeatMap.seatTooltip__tooltip
        },
        ComponentStyles: {
            right: SeatMap.component__right
        }
    },
    SegmentTabs: {
        SegmentTabs: {
            wrapper: SegmentTabs.segmentTabs__wrapper,
            inner: SegmentTabs.segmentTabs__inner,
            title: SegmentTabs.segmentTabs__title
        },
        SegmentTab: {
            segment: SegmentTabs.segmentTab__segment,
            segment_active: SegmentTabs.segmentTab__segmentActive,
            checked: SegmentTabs.segmentTab__checked
        }
    },
    MealService: {
        List: {
            wrapper: MealService.list__wrapper,
            item: MealService.list__item,
            item_selected: MealService.list__itemSelected,
            item__icon: MealService.list__itemIcon
        },
        CategoriesStyles: {
            mobile__header: MealService.categories__mobileHeader
        }
    },
    Meal: {
        Meal: {
            meal: Meal.meal__meal,
            image: Meal.meal__image,
            name: Meal.meal__name,
            price: Meal.meal__price,
            price__selected: Meal.meal__priceSelected,
            popup__close: Meal.meal__popupClose
        },
        Details: {
            content: Meal.details__content,
            name: Meal.details__name,
            row: Meal.details__row,
            trigger: Meal.details__trigger,
            price: Meal.details__price,
            counter: Meal.details__counter,
            passenger: Meal.details__passenger,
            passenger__index: Meal.details__passengerIndex,
            footer: Meal.details__footer,
            totalText: Meal.details__totalText,
            total: Meal.details__total,
            clear: Meal.details__clear,
            description__row: Meal.details__descriptionRow,
            addForAll: Meal.details__addForAll,
            button_empty: Meal.details__buttonEmpty,
            image: Meal.details__image,
            selector: Meal.details__selector
        }
    },
    Counter: {
        Counter: {
            button: Counter.counter__button,
            value: Counter.counter__value,
            button_disabled: Counter.counter__buttonDisabled
        }
    },
    ServiceRecommendation: {
        Meal: {
            details__header: ServiceRecommendation.meal__detailsHeader,
            title: ServiceRecommendation.meal__title,
            text: ServiceRecommendation.meal__text,
            price: ServiceRecommendation.meal__price,
            price__money: ServiceRecommendation.meal__priceMoney
        }
    },
    AdditionalServiceBanner: {
        AdditionalServiceBannerStyles: {
            wrapper: AdditionalServiceBanner.additionalServiceBanner__wrapper,
            text: AdditionalServiceBanner.additionalServiceBanner__text,
            disclaimer: AdditionalServiceBanner.additionalServiceBanner__disclaimer,
            image: AdditionalServiceBanner.additionalServiceBanner__image,
            disclaimer__icon: AdditionalServiceBanner.additionalServiceBanner__disclaimerIcon
        }
    },
    IncludedBaggage: {
        IncludedBaggage: {
            root: IncludedBaggage.includedBaggage__root,
            header: IncludedBaggage.includedBaggage__header
        }
    },
    AdditionalBaggage: {
        AdditionalBaggage: {
            header: AdditionalBaggage.additionalBaggage__header,
            promo_message: AdditionalBaggage.additionalBaggage__promoMessage
        }
    },
    BaggageList: {
        BaggageList: {
            item: BaggageList.baggageList__item,
            price: BaggageList.baggageList__price
        },
        BaggageCount: {
            root: BaggageList.baggageCount__root
        }
    },
    Baggage: {
        Baggage: {
            name: Baggage.baggage__name,
            size: Baggage.baggage__size
        },
        BaggageIcon: {
            wrapper: Baggage.baggageIcon__wrapper,
            size: Baggage.baggageIcon__size
        }
    },
    BaggageCard: {
        BaggageCard: {
            title: BaggageCard.baggageCard__title,
            description: BaggageCard.baggageCard__description,
            icon: BaggageCard.baggageCard__icon,
            price: BaggageCard.baggageCard__price,
            button: BaggageCard.baggageCard__button,
            button_edit: BaggageCard.baggageCard__buttonEdit,
            header: BaggageCard.baggageCard__header
        }
    },
    BaggageTotalPriceBlock: {
        BaggageTotalPriceBlock: {
            price: BaggageTotalPriceBlock.baggageTotalPriceBlock__price,
            control: BaggageTotalPriceBlock.baggageTotalPriceBlock__control,
            control__edit: BaggageTotalPriceBlock.baggageTotalPriceBlock__controlEdit
        }
    },
    UpgradeBaggageDialog: {
        UpgradeBaggageDialog: {
            modalHeader: UpgradeBaggageDialog.upgradeBaggageDialog__modalHeader
        },
        BaggageDialogHeader: {
            description: UpgradeBaggageDialog.baggageDialogHeader__description,
            price_label: UpgradeBaggageDialog.baggageDialogHeader__priceLabel,
            price_wrapper: UpgradeBaggageDialog.baggageDialogHeader__priceWrapper,
            category_title: UpgradeBaggageDialog.baggageDialogHeader__categoryTitle,
            label: UpgradeBaggageDialog.baggageDialogHeader__label,
            category__price: UpgradeBaggageDialog.baggageDialogHeader__categoryPrice,
            category__wrapper: UpgradeBaggageDialog.baggageDialogHeader__categoryWrapper
        },
        BaggagePassenger: {
            root: UpgradeBaggageDialog.baggagePassenger__root,
            number: UpgradeBaggageDialog.baggagePassenger__number,
            name: UpgradeBaggageDialog.baggagePassenger__name,
            checkbox_root: UpgradeBaggageDialog.baggagePassenger__checkboxRoot,
            checkbox_rootChecked: UpgradeBaggageDialog.baggagePassenger__checkboxRootChecked,
            counter_wrapper: UpgradeBaggageDialog.baggagePassenger__counterWrapper
        },
        BaggageFooter: {
            root: UpgradeBaggageDialog.baggageFooter__root,
            price_wrapper: UpgradeBaggageDialog.baggageFooter__priceWrapper,
            price_block__delimiter: UpgradeBaggageDialog.baggageFooter__priceBlockDelimiter,
            clear: UpgradeBaggageDialog.baggageFooter__clear,
            cancel_btn: UpgradeBaggageDialog.baggageFooter__cancelBtn,
            confirm_btn: UpgradeBaggageDialog.baggageFooter__confirmBtn
        },
        HeaderEquipmentItem: {
            wrapper: UpgradeBaggageDialog.headerEquipmentItem__wrapper,
            active: UpgradeBaggageDialog.headerEquipmentItem__active
        },
        BaggageSegment: {
            root: UpgradeBaggageDialog.baggageSegment__root,
            name: UpgradeBaggageDialog.baggageSegment__name,
            price: UpgradeBaggageDialog.baggageSegment__price
        },
        MobileBaggageSelector: {
            price: UpgradeBaggageDialog.mobileBaggageSelector__price,
            footer: UpgradeBaggageDialog.mobileBaggageSelector__footer
        }
    },
    BaggageCounter: {
        BaggageCounter: {
            plus: BaggageCounter.baggageCounter__plus,
            minus: BaggageCounter.baggageCounter__minus
        }
    },
    BaggagePage: {
        BaggagePage: {
            baggage: BaggagePage.baggage
        }
    },
    OverweightBaggage: {
        OverweightBaggage: {
            flight_info: OverweightBaggage.overweightBaggage__flightInfo,
            flight_direction: OverweightBaggage.overweightBaggage__fligntDirection
        }
    },
    SpecialEquipment: {
        SpecialEquipment: {
            flight_info: SpecialEquipment.specialEquipment__flightInfo,
            flight_direction: SpecialEquipment.specialEquipment__flightDirection
        }
    },
    SearchForm: {
        SearchForm: {
            searchForm: cn(SearchForm.rmSearchForm, Forms.rmSearchForm),
            row: cn(SearchForm.rmSearchForm__row, Forms.rmSearchForm__row)
        },
        Segments: {
            segments: SearchForm.rmSearchForm__segments,
            segments_multiCity: SearchForm.rmSearchForm__segments_multiCity
        },
        Segment: {
            wrapper: SearchForm.rmSearchForm__wrapper,
            segment: SearchForm.rmSearchForm__segment,
            cell: SearchForm.rmSearchForm__cell,
            input: SearchForm.rmSearchForm__input,
            input_focused: SearchForm.rmSearchForm__input_focused,
            alternative: SearchForm.rmSearchForm__alternative,
            cell_date: SearchForm.rmSearchForm__cell_date,
            cell_location: SearchForm.rmSearchForm__cell_location,
            withMultiCity: SearchForm.rmSearchForm__segment_withMultiCity,
            location__value: SearchForm.rmSearchForm__locationValue,
            control: SearchForm.rmSearchForm__control
        },
        StartSearch: {
            startSearch_iconMode: SearchForm.rmSearchForm__startSearchIconMode,
            startSearch: cn(SearchForm.rmSearchForm__startSearch, Forms.searchForm__startSearch),
            startSearch_multiCity: cn(SearchForm.rmSearchForm__startSearchMultiCity, Forms.rmSearchForm__startSearchMultiCity)
        },
        Currency: {
            currency: cn(SearchForm.rmCurrency, Forms.searchForm__currency),
            selector: cn(SearchForm.rmCurrency__selector, Forms.searchForm__currencySelector),
            selector__inner: SearchForm.currency__selectorInner,
            arrowIcon: cn(SearchForm.currency__icon, Forms.searchForm__currencyArrowIcon),
            arrowIcon_flipped: SearchForm.currency__icon_flipped,
            dropdown: SearchForm.rmCurrency__dropdown,
            item: SearchForm.rmCurrency__item
        },
        Value: {
            wrapper: cn(SearchForm.rmSearchForm__valueWrapper, Forms.searchForm__valueWrapper),
            wrapper_focused: SearchForm.rmSearchForm__valueWrapper_focused,
            value: SearchForm.rmSearchForm__value,
            code: SearchForm.rmSearchForm__code,
            placeholder: SearchForm.rmSearchForm__placeholder,
            wrapper_alternative: SearchForm.rmSearchForm__wrapper_alternative
        },
        Datepicker: {
            dates: cn(SearchForm.rmSearchForm__dates, Forms.rmSearchForm__dates),
            dates_focused: SearchForm.rmSearchForm__dates_focused,
            dow: SearchForm.rmSearchForm__dow,
            returnDelete: cn(SearchForm.rmSearchForm__returnDelete, Forms.searchForm__returnDelete),
            dates__to: cn(SearchForm.rmSearchForm__to, Forms.searchForm__datesTo),
            dates__back: SearchForm.rmSearchForm__back,
            dates__to_focused: SearchForm.rmSearchForm__to_focused,
            dates__back_focused: SearchForm.rmSearchForm__back_focused,
            alternative: SearchForm.rmSearchForm__dates_alternative,
            oneDate: SearchForm.rmSearchForm__dates_oneDate,
            placeholder: cn(SearchForm.rmSearchForm__dates_placeholder, Forms.searchForm__datesPlaceholder),
            alternative_dow: SearchForm.datepicker__alternativeDow
        },
        Location: {
            switcher: SearchForm.rmSearchForm__switcher,
            switcher_alternative: SearchForm.rmSearchForm__switcher_alternative,
            autocomplete_alternative: SearchForm.rmSearchForm__autocomplete_alternative,
            input: SearchForm.rmSearchForm__autocomplete_input,
            input_withoutPlaceholder: SearchForm.location__inputWithoutPlaceholder
        },
        Passengers: {
            passengers: cn(SearchForm.rmSearchForm__passengers, Forms.searchForm__passengers),
            passengers_isMultiCity: cn(SearchForm.rmSearchForm__passengers_isMultiCity, Forms.searchForm__passengersIsMultiCity),
            passengers_withCurrency: SearchForm.rmSearchForm__passengers_withCurrency,
            selector: cn(SearchForm.rmSearchForm__selector, Forms.searchForm__passengersSelector),
            selector_focused: cn(SearchForm.rmSearchForm__selector_focused, Forms.searchForm__passengersSelectorFocused),
            arrowIcon: SearchForm.rmSearchForm__arrowIcon,
            arrowIcon_flipped: SearchForm.rmSearchForm__arrowIcon_flipped,
            menu: SearchForm.rmSearchForm__passengersMenu,
            mobileTitle: SearchForm.rmSearchForm__mobileTitle,
            title: cn(Forms.searchForm__passengersTitle)
        },
        Controls: {
            controls: SearchForm.rmSearchForm__controls,
            controls_multiCity: cn(SearchForm.rmSearchForm__controls_multiCity, Forms.searchForm__controlsMultiCity)
        },
        Actions: {
            actions: SearchForm.rmSearchForm__actions,
            addSegment: SearchForm.rmSearchForm__actionsAdd,
            removeSegment: SearchForm.rmSearchForm__actionsRemove
        },
        OfferHotels: {
            switchItem: SearchForm.rmSearchForm__switchItem,
            toggle_active: SearchForm.rmSearchForm__toggleActive
        }
    },
    SearchFormDatepicker: {
        MonthHeaderStyles: {
            monthHeader__select_month: SearchForm.rmSearchForm__select_month,
            monthHeader__select_year: SearchForm.rmSearchForm__select_year
        },
        WeeklyHeaderStyles: {
            day: SearchForm.rmSearchForm__dayWeek,
            dayOfWeekName: SearchForm.rmSearchForm__dayOfWeekName,
            dayOfWeekName_withPrices: SearchForm.rmSearchForm__dayOfWeekName_withPrices
        },
        MonthStyles: {
            week_withPrice: SearchForm.rmSearchForm__week_withPrice,
            month: SearchForm.rmSearchForm__month
        },
        MonthsWrapperStyles: {
            nav_next: SearchForm.monthsWrapper__navNext,
            nav_prev: SearchForm.monthsWrapper__navPrev
        },
        DayStyles: {
            day: SearchForm.rmSearchForm__day,
            day_inPeriod: SearchForm.rmSearchForm__day_inPeriod,
            day_selected: SearchForm.rmSearchForm__day_selected,
            day_withPrice: SearchForm.rmSearchForm__day_withPrice,
            day_startPeriod: SearchForm.rmSearchForm__day_startPeriod,
            day_today: SearchForm.rmSearchForm__day_today,
            day_weekend: SearchForm.rmSearchForm__day_weekend,
            day_startHover_withprice: SearchForm.rmSearchForm__day_startHover_withprice,
            day_startHover_withPrice: SearchForm.rmSearchForm__day_startHover_withPrice,
            day_endHover_withPrice: SearchForm.rmSearchForm__day_endHover_withPrice,
            day__price: SearchForm.rmSearchForm__day_price
        },
        SearchFormDatepickerStyles: {
            popup: SearchForm.rmSearchForm__popup,
            datepicker__disclaimer: SearchForm.rmSearchForm__disclaimer,
            tab: SearchForm.rmSearchForm__tab,
            tab_selected: SearchForm.rmSearchForm__tab_selected,
            tab__indicator: SearchForm.rmSearchForm__tab_indicator,
            tab__wrapper: SearchForm.rmSearchForm__tab_wrapper,
            footer__done: SearchForm.rmSearchForm__footer_done,
            footer__clear: SearchForm.rmSearchForm__footer_clear,
            dialog__bar: SearchForm.rmSearchForm__dialogBar,
            dialog__activeDate: SearchForm.rmSearchForm__dialog_activeDate,
            footer: SearchForm.rmSearchForm__footerBar,
            tabs: SearchForm.rmSearchForm__tabs,
            dialog__close: SearchForm.rmSearchForm__dialogClose,
            footer__buttons: SearchForm.rmSearchForm__footerButtons,
            content: SearchForm.searchFormDatepicker__content
        }
    },
    LocationAutocomplete: {
        Group: {
            group__label: LocationAutocomplete.group__label
        },
        Dropdown: {
            options: LocationAutocomplete.options,
            allDirections__button: LocationAutocomplete.allDirections__button
        },
        Option: {
            label: LocationAutocomplete.option__label,
            code: LocationAutocomplete.option__code
        }
    },
    Datepicker: {
        DayStyles: {
            day: SearchForm.rmSearchForm__day,
            day_weekend: SearchForm.rmSearchForm__day_weekend,
            day_notAvailable: SearchForm.rmSearchForm__day_notAvailable,
            day_today: SearchForm.rmSearchForm__dayToday
        }
    },
    Passengers: {
        PassengerStyles: {
            decrease: SearchForm.rmSearchForm__decrease,
            increase: SearchForm.rmSearchForm__increase,
            counter: SearchForm.rmSearchForm__counter,
            counter_disabled: SearchForm.rmSearchForm__counter_disabled,
            passengerType: SearchForm.rmSearchForm__passengerType,
            age: SearchForm.rmSearchForm__age
        }
    },
    Refund: {
        Refund: {
            stepTitle: Refund.rmRefund__stepTitle,
            mobileStep: Refund.rmRefund__mobileStep
        },
        StepTitle: {
            icon: Refund.rmRefund__stepTitle_icon,
            text: Refund.rmRefund__stepTitle_text
        },
        Breadcrumbs: {
            breadcrumb: Refund.rmRefund__breadcrumb,
            close: Refund.rmRefund__breadcrumbClose
        },
        RefundPaper: {
            paper: Refund.rmRefund__paper
        },
        Passengers: {
            passenger: Refund.rmRefund__passengerLabel
        },
        Passenger: {
            passenger: Refund.rmRefund__passenger,
            label: Refund.rmRefund__passengerLabel,
            checkbox: Refund.rmRefund__passengerCheckbox,
            active: Refund.rmRefund__passenger_active
        },
        StepsButtons: {
            button: Refund.rmRefund__stepsButton
        },
        Reason: {
            radio: Refund.rmRefund__radio,
            active: Refund.rmRefund__radio_active,
            label: Refund.rmRefund__reasonLabel
        },
        ReasonForm: {
            groupTitle: Refund.rmRefund__groupTitle,
            reason: Refund.rmRefund__reason,
            reasons: Refund.rmRefund__reasons
        },
        RequestForm: {
            checkbox: Refund.rmRefund__requestCheckbox,
            active: Refund.rmRefund__request_active,
            formLabel: Refund.rmRefund__requestLabel,
            agree: Refund.rmRefund__requestAgree
        },
        Price: {
            total: Refund.rmRefund__priceTotal,
            row: Refund.rmRefund__priceRow,
            price: Refund.rmRefund__price
        },
        RefundInfoModal: {
            content: Refund.rmRefund__modalContent,
            button: Refund.rmRefund__modalButton
        },
        MobileStep: {
            header: Refund.rmRefund__mobileStepHeader
        },
        Note: {
            note: Refund.rmRefund__note,
            text: Refund.rmRefund__noteText,
            icon: Refund.rmRefund__noteIcon,
            gray: Refund.rmRefund__noteGray
        },
        Ticket: {
            price: Refund.ticket__price
        },
        Status: {
            processing: Refund.status__processing,
            waiting: Refund.status__waiting
        }
    },
    Exchange: {
        Inner: {
            container: Exchange.inner__container
        },
        Chip: {
            chip: Exchange.rmExchange__chip,
            close: Exchange.rmExchange__chipClose
        },
        CommonSelect: {
            header: Exchange.rmExchange__commonHeader,
            icon: Exchange.rmExchange__commonIcon,
            button: Exchange.rmExchange__commonButton
        },
        Choice: {
            container: Exchange.rmExchange__choiceContainer,
            label: Exchange.rmExchange__choiceLabel,
            button: Exchange.rmExchange__choiceCheckbox,
            label_selected: Exchange.rmExchange__choiceLabel_selected,
            button_checked: Exchange.rmExchange__choiceCheckbox_selected
        },
        Datepicker: {
            wrapper: Exchange.rmExchange__datepickerWrapper
        },
        Selections: {
            wrapper: Exchange.rmExchange__selectionsWrapper
        },
        SearchFlights: {
            header: Exchange.rmExchange__flightsHeader,
            leg: Exchange.rmExchange__flightsLeg,
            route: Exchange.rmExchange__flightsRoute
        },
        Calendar: {
            calendar: Exchange.rmExchange__flightsCalendar,
            monthsList: Exchange.calendar__monthsList,
            day: Exchange.calendar__day,
            day_inside: Exchange.calendar__dayInside,
            day_selected: Exchange.calendar__daySelected,
            day_highlight: Exchange.calendar__dayHighlight
        },
        SelectedFlights: {
            flights: Exchange.selectedFlights__flights,
            change: Exchange.rmExchange__flightsChange,
            header__name: Exchange.rmExchange__flightsHeaderName,
            flight__container: Exchange.selectedFlights__flightContainer,
            container_flat: Exchange.selectedFlights__containerFlat,
            charge: Exchange.selectedFlights__charge,
            charge__money: Exchange.selectedFlights__chargeMoney,
            price: Exchange.selectedFlights__price,
            total: Exchange.selectedFlights__total,
            total__money: Exchange.selectedFlights__totalMoney
        },
        Disclaimer: {
            wrapper: Exchange.rmExchange__flightsDisaclaimer
        },
        CreateRequest: {
            controls: Exchange.rmExchange__flightsCreateControls,
            button: Exchange.rmExchange__flightsCreateButton,
            button_checked: Exchange.rmExchange__flightsCreateButton_checked,
            submit: Exchange.rmExchange__flightsCreateSubmit
        },
        Dialog: {
            paper: Exchange.rmExchange__dialogPaper
        },
        RequestForm: {
            header: Exchange.rmExchange__requestFormHeader,
            created: Exchange.rmExchange__requestFormCreated
        }
    },
    OrderCard: {
        OrderCard: {
            root: OrderCard.rmOrder__root,
            openOrder: OrderCard.rmOrder__openOrder,
            container: OrderCard.container,
            type_account: OrderCard.type_account
        },
        CommonItem: {
            root: OrderCard.rmOrder__item,
            icon: OrderCard.rmOrder__itemIcon,
            payload: OrderCard.rmOrder__payload
        },
        OrderPassengers: {
            control: OrderCard.rmOrder__control
        },
        OrderTickets: {
            link: OrderCard.rmOrder__control
        },
        OrderChange: {
            button: OrderCard.rmOrder__changeButton
        },
        OrderBooking: {
            download: OrderCard.rmOrder__downloadButton,
            root: OrderCard.rmOrder__bookingRoot,
            type_account: OrderCard.rmOrder__bookingAccount,
            type_review: OrderCard.rmOrder__bookingReview,
            orderCode: OrderCard.rmOrder__orderCode
        },
        OrderPreview: {
            inner: OrderCard.rmOrder__previewInner
        },
        PaymentStatus: {
            root: cn(OrderCard.rmOrder__status)
        }
    },
    CheckoutAdditionalService: {
        CheckoutAdditionalService: {
            service: OrderCard.rmCheckoutService,
            service_active: OrderCard.rmCheckoutService_active,
            header: OrderCard.rmCheckoutService__header,
            icon: OrderCard.rmCheckoutService__icon,
            header__price: OrderCard.rmCheckoutService__price,
            content: OrderCard.rmCheckoutService__content,
            list__item: OrderCard.rmCheckoutService__listItem,
            list__cities: OrderCard.rmCheckoutService__listCities,
            list__services: OrderCard.rmCheckoutService__listServices,
            buttons: OrderCard.rmCheckoutService__buttons
        },
        CheckoutAdditionalServiceBaggage: {
            baggage: OrderCard.rmCheckoutService_baggage
        },
        CheckoutAdditionalServiceMeal: {
            meal: OrderCard.rmCheckoutService_meal
        },
        Seats: {
            seats: OrderCard.rmCheckoutService_seats
        },
        Insurance: {
            insurance: OrderCard.rmCheckoutService_insurance
        },
        Loyalty: {
            loyalty: OrderCard.rmCheckoutService_loyalty
        },
        Aeroexpress: {
            aeroexpress: OrderCard.rmCheckoutService_aeroexpress
        },
        Business: {
            business: OrderCard.rmCheckoutService_business
        },
        CapsuleHotel: {
            capsuleHotel: OrderCard.rmCheckoutService_capsule
        },
        PriorityBoarding: {
            priority: OrderCard.rmCheckoutService_priority
        }
    },
    Insurance: {
        InsuranceCardStyles: {
            insurance: Insurance.rmInsurance,
            insurance__money: Insurance.rmInsurance__money,
            insurance__header: Insurance.rmInsurance__header,
            selected: Insurance.rmInsurance_selected,
            option: Insurance.rmInsurance__option,
            insurance__button: Insurance.rmInsurance__button,
            insurance__button_selected: Insurance.rmInsurance__button_selected
        },
        InsuranceStyles: {
            dialog: Insurance.rmInsuranceDialog,
            wrapper: Insurance.rmInsuranceDialog__wrapper,
            mobileWrapper: Insurance.rmInsuranceDialog__mobileWrapper,
            dialog__header: Insurance.rmInsuranceDialog__header,
            rules: Insurance.rmInsuranceDialog__rules,
            dialog__button_clear: Insurance.rmInsuranceDialog__button_clear,
            dialog__button_done: Insurance.rmInsuranceDialog__button_done
        },
        MobileScreenStyles: {
            header: Insurance.rmInsuranceDialog__mobileHeader,
            rules: Insurance.rmInsuranceDialog__mobileRules
        }
    },
    Warnings: {
        WarningsStyles: {
            wrapper: Warnings.rmWarning,
            warning: Warnings.rmWarning__inner,
            warning__text: Warnings.rmWarning__text,
            icon__text: Warnings.rmWarning__iconText,
            warning__icon: Warnings.rmWarning__icon
        }
    },
    OrderSummary: {
        OrderStub: {
            stub__content: Account.rmOrderStub__content
        }
    },
    Traveller: {
        Traveller: {
            wrapper: Account.rmTraveller,
            title__traveller: Account.rmTraveller__title,
            add: Account.rmTraveller__add,
            header: Account.rmTraveller__header,
            content__traveller: Account.rmTraveller__content
        }
    },
    LoginPage: {
        Form: {
            loginForm: LoginPage.rmLoginForm,
            loginForm__button: LoginPage.rmLoginForm__button,
            social: LoginPage.rmLoginForm__social,
            social__buttons: LoginPage.rmLoginForm__socialButtons,
            social__text: LoginPage.rmLoginForm__socialText,
            wrapper: LoginPage.rmLogin__wrapper,
            alternative: LoginPage.rmLogin__alternative
        },
        Header: {
            header: LoginPage.rmLogin__header,
            buttons: LoginPage.rmLogin__headerButtons,
            prevButton: LoginPage.rmLogin__prevButton,
            step: LoginPage.rmLogin__step,
            title: LoginPage.rmLogin__title
        },
        Alternative: {
            item: LoginPage.rmLogin__alternativeItem
        },
        Verification: {
            LoginVerification__text: LoginPage.rmLogin__verificationText,
            LoginVerification__form: LoginPage.rmLogin__verificationForm,
            LoginVerification__field: LoginPage.rmLogin__verificationField,
            LoginVerification__input: LoginPage.rmLogin__verificationInput,
            submit_button: LoginPage.rmLogin__verificationSubmit,
            footer: LoginPage.rmLogin__verificationFooter
        }
    },
    AccountModule: {
        Account: {
            wrapper: Account.rmAccount,
            loginWrapper: LoginPage.rmAccountLogin,
            active__route: Account.rmAccount__activeRoute,
            route__title: Account.rmAccount__title,
            account__fullscreen: Account.rmAccount__fullscreen
        }
    },
    Account: {
        Account: {
            account__offers: Account.rmAccount__offers,
            checkbox: Account.rmAccount__checkbox,
            checked: Account.rmAccount__checked,
            account__footer: Account.rmAccount__footer,
            social__networks: Account.rmAccount__socialNetwork
        },
        SocialButton: {
            add__icon: Account.rmAccount__socialAdd
        },
        InfoField: {
            indicator: InfoField.indicator,
            tooltip: InfoField.tooltip
        }
    },
    DocumentForm: {
        DocumentForm: {
            title: DocumentForm.rmDocument__title,
            form: DocumentForm.rmDocument__form,
            footer: DocumentForm.rmDocument__footer
        }
    },
    Checkin: {
        Inital: {
            wrapper: Checkin.rmInital,
            form: Checkin.rmInital__form,
            hint: Checkin.rmInital__hint,
            hint__title: Checkin.rmInital__hintTitle,
            hint__info: Checkin.rmInital__hintInfo,
            hint__info__icon: Checkin.rmInital__hintIcon,
            hint__info__text: Checkin.rmInital__hintText,
            hint__image: Checkin.rmInital__hintImage
        },
        Passengers: {
            wrapper: Checkin.rmCheckin,
            segments: Checkin.rmCheckin__segments,
            title: Checkin.rmCheckin__title,
            passengers: Checkin.rmCheckin__passengers,
            toolbar: Checkin.rmCheckin__toolbar,
            toolbar__button: Checkin.rmCheckin__toolbarButton,
            checkinCancelModal__text: Checkin.rmCheckinCancelModal__text
        },
        SelectSegmentsModal: {
            header: Checkin.rmSelectSegmentsModal__header,
            button: Checkin.rmSelectSegmentsModal__button
        },
        VisaForm: {
            header: VisaForm.header,
            saveButton: VisaForm.saveButton
        }
    },
    VisaForm: {
        VisaForm: {
            group__field: VisaForm.group__field
        }
    },
    RetrieveBooking: {
        RetrieveBooking: {
            container: Checkin.rmRetrieveBooking,
            title: Checkin.rmRetrieveBooking__title,
            title__icon: Checkin.rmRetrieveBooking__icon,
            form: Checkin.rmRetrieveBooking__form,
            form__row: Checkin.rmRetrieveBooking__row,
            button: Checkin.rmRetrieveBooking__button
        },
        ClarifyDocumentModal: {
            header: Checkin.rmClarifyDocument__header,
            message: Checkin.rmClarifyDocument__message,
            form: Checkin.rmClarifyDocument__form,
            submit: Checkin.rmClarifyDocument__submit
        }
    },
    SelectableSegment: {
        SelectableSegmentDesktop: {
            dateWrp: Checkin.rmSelectableSegmentDesktop__dateWrp,
            time: Checkin.rmSelectableSegmentDesktop__time,
            flight: Checkin.rmSelectableSegmentDesktop__flight,
            route: Checkin.rmSelectableSegmentDesktop__route,
            iata: Checkin.rmSelectableSegmentDesktop__iata,
            airplane: Checkin.rmSelectableSegmentDesktop__airplane
        },
        SelectableSegmentMobile: {
            wrapper: Checkin.rmSelectableSegmentMobile,
            flightInfo__time: Checkin.rmSelectableSegmentMobile__time,
            flightInfo__iatas: Checkin.rmSelectableSegmentMobile__iatas,
            planeIcon: Checkin.rmSelectableSegmentMobile__planeIcon,
            flightInfo__cities: Checkin.rmSelectableSegmentMobile__cities,
            mobileSegment__bottom: Checkin.rmSelectableSegmentMobile__bottom
        }
    },
    Segment: {
        SegmentCommon: {
            wrapper: Checkin.rmSegment,
            header: Checkin.rmSegment__header,
            cities: Checkin.rmSegment__cities,
            city__airport: Checkin.rmSegment__cityAirport,
            city__info: Checkin.rmSegment__cityInfo,
            city__iata: Checkin.rmSegment__cityIata,
            plane__icon: Checkin.rmSegment__planeIcon,
            flightInfo: Checkin.rmSegment__flightInfo,
            flightInfo__item: Checkin.rmSegment__flightItem,
            flightInfo__title: Checkin.rmSegment__flightTitle,
            flightInfo__value: Checkin.rmSegment__flightValue,
            footer: Checkin.rmSegment__footer,
            airline__name: Checkin.rmSegment__airlaneName,
            footer__item: Checkin.rmSegment__footerItem
        }
    },
    SelectedPassenger: {
        SelectedPassenger: {
            wrapper: Checkin.rmSelectedPassenger,
            passenger: Checkin.rmSelectedPassenger__passenger,
            passenger__icon: Checkin.rmSelectedPassenger__icon,
            rightSide: Checkin.rmSelectedPassenger__rightSide,
            passengerInfo: Checkin.rmSelectedPassenger__info,
            passengerInfo__item: Checkin.rmSelectedPassenger__infoItem,
            passengerInfo__field: Checkin.rmSelectedPassenger__infoField,
            loyalty__link: Checkin.rmSelectedPassenger__loyaltyLink
        },
        LoyaltyNumber: {
            header: Checkin.rmLoyaltyNumber__header,
            container: Checkin.rmLoyaltyNumber__container,
            input__wrapper: Checkin.rmLoyaltyNumber__inputWrapper,
            input: Checkin.rmLoyaltyNumber__input,
            button: Checkin.rmLoyaltyNumber__button,
            modal__scroll: Checkin.rmLoyaltyNumber__modalScroll,
            modal: Checkin.rmLoyaltyNumber__modal
        }
    },
    AddPassenger: {
        AddPassenger: {
            wrapper: Checkin.rmAddPassenger,
            addButton: Checkin.rmAddPassenger__addButton,
            addButton__icon: Checkin.rmAddPassenger__addButtonIcon,
            passengerIcon: Checkin.rmAddPassenger__passengerIcon,
            form: Checkin.rmAddPassenger__form,
            form__input: Checkin.rmAddPassenger__formInput,
            submitButton: Checkin.rmAddPassenger__submitButton
        }
    },
    MobileSegment: {
        MobileSegment: {
            wrapper: Checkin.rmMobileSegment,
            fareGroup: Checkin.rmMobileSegment__fareGroup,
            fareGroup__info: Checkin.rmMobileSegment__fareGroupInfo,
            flightNumber: Checkin.rmMobileSegment__flightNumber,
            flightInfo: Checkin.rmMobileSegment__flightInfo,
            flightInfo__iatas: Checkin.rmMobileSegment__flightInfoIatas,
            planeIcon: Checkin.rmMobileSegment__planeIcon,
            flightInfo__cities: Checkin.rmMobileSegment__flightInfoCities,
            flightInfo__dates: Checkin.rmMobileSegment__flightInfoDates,
            flightInfo__time: Checkin.rmMobileSegment__flightInfoTime,
            flightInfo__weather: Checkin.rmMobileSegment__flightInfoWeather,
            arrivalInfo: Checkin.rmMobileSegment__arrivalInfo,
            arrivalInfo__item: Checkin.rmMobileSegment__arrivalInfoItem,
            arrivalInfo__title: Checkin.rmMobileSegment__arrivalInfoTitle
        }
    },
    RegisteredPassenger: {
        CancelModal: {
            content: Checkin.rmCancelModal__content
        },
        RegisteredPassenger: {
            cancelModal: Checkin.rmCancelModal
        }
    },
    ResendTicketsForm: {
        ResendTicketsForm: {
            close: Checkin.rmResendTicketsForm__close,
            wrapper: Checkin.rmResendTicketsForm__wrapper
        }
    },
    PaymentResult: {
        PaymentResult: {
            redirectButton: PaymentResult.redirectButton
        }
    },
    VipServiceSelect: {
        VipServiceSelect: {
            cancel: VipServiceSelect.cancel
        }
    },
    MobileStepbar: {
        StepbarHeader: {
            header_zeroPrice: MobileStepbar.header_zeroPrice,
            price__wrapper: MobileStepbar.price__wrapper
        }
    },
    PromoLoader: {
        PromoLoader: {
            wrapper: PromoLoader.wrapper
        }
    }
};
export default theme;
