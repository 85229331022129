import * as React from 'react';
import { useSelector } from 'react-redux';
import { useConfig } from '../../../../context';
import { getAnimalServices, getOrder, getSingleInsurancePrograms } from '../../../store/order/selectors';
import { useCheckoutServicesCardsAvailability } from '../../../../CheckoutAdditionalService/components/hooks';
import { Transfer, UpgradeFlightClass } from '../../../../CheckoutAdditionalService/components';
import { OverrideComponent } from '../../../../renderProps';
import CardExtra from '../../../../CardExtra/components/CardExtra';
const ServicesV2 = ({ userInfo, updateUser }) => {
    var _a, _b, _c;
    const order = useSelector(getOrder);
    const servicesAvailability = useCheckoutServicesCardsAvailability();
    const withOutSingleInsurancePrograms = useSelector(getSingleInsurancePrograms(true));
    const singleInsurancePrograms = useSelector(getSingleInsurancePrograms());
    const animalServices = useSelector(getAnimalServices);
    const hasAnimal = ((_b = (_a = useConfig()) === null || _a === void 0 ? void 0 : _a.global.servicesAvailable) === null || _b === void 0 ? void 0 : _b.animal) === true;
    return (React.createElement(React.Fragment, null,
        servicesAvailability.upgradeFlightClass && React.createElement(UpgradeFlightClass, null),
        servicesAvailability.Seat && React.createElement(CardExtra, { type: "seats" }),
        servicesAvailability.Baggage && React.createElement(CardExtra, { type: "baggage" }),
        servicesAvailability.Meal && React.createElement(CardExtra, { type: "meal" }),
        servicesAvailability.Insurance && (React.createElement(OverrideComponent, { componentProps: {}, component: {
                CheckoutInsurance: () => (React.createElement(React.Fragment, null,
                    withOutSingleInsurancePrograms.length > 0 && (React.createElement(CardExtra, { type: "insurance", insuranceCodes: withOutSingleInsurancePrograms.map(({ code }) => code) })),
                    singleInsurancePrograms.map((insurance, index) => (React.createElement(CardExtra, { key: index, type: "insurance", insuranceCodes: [insurance.code] })))))
            } })),
        servicesAvailability.Aeroexpress && React.createElement(CardExtra, { type: "transfer" }),
        servicesAvailability.Transfer && React.createElement(Transfer, null),
        ((_c = order === null || order === void 0 ? void 0 : order.ffpInfo) === null || _c === void 0 ? void 0 : _c.ffpSupported) && React.createElement(CardExtra, { type: "loyalty", userInfo: userInfo, updateUser: updateUser }),
        servicesAvailability.BusinessLounge && React.createElement(CardExtra, { type: "business-lounge" }),
        servicesAvailability.PriorityBoarding && React.createElement(CardExtra, { type: "priority-boarding" }),
        servicesAvailability.CapsuleHotel && React.createElement(CardExtra, { type: "capsule-hotel" }),
        hasAnimal && animalServices.length > 0 && React.createElement(CardExtra, { type: "animal" }),
        servicesAvailability.BaggageDelivery && React.createElement(CardExtra, { type: "baggage-delivery" }),
        servicesAvailability.SMS && React.createElement(CardExtra, { type: "sms" })));
};
export default ServicesV2;
