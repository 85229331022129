import * as React from 'react';
import Helmet from 'react-helmet';
var Head = function () {
    return (React.createElement(Helmet, null,
        React.createElement("link", { rel: "stylesheet", href: "https://cdnjs.cloudflare.com/ajax/libs/normalize/8.0.1/normalize.min.css" }),
        React.createElement("link", { rel: "stylesheet", type: "text/css", href: "https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css" }),
        React.createElement("link", { rel: "stylesheet", type: "text/css", href: "https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css" }),
        React.createElement("link", { rel: "preconnect", href: "https://fonts.googleapis.com" }),
        React.createElement("link", { rel: "preconnect", href: "https://fonts.gstatic.com", crossorigin: "" }),
        React.createElement("link", { href: "https://fonts.googleapis.com/css2?family=Noto+Sans+Armenian:wght@100;200;300;400;500;600;700;800;900&display=swap", rel: "stylesheet" })));
};
export default Head;
