var _a;
import { __assign } from "tslib";
import * as React from 'react';
import RootComponent from '@websky/core/src/SeatMap/components/SeatMap/Seat/Seat';
import { SeatMapColorsContext, ArmeniaSeatColors } from '../SeatMap';
import css from './Seat.css';
var colorsClass = (_a = {},
    _a[ArmeniaSeatColors.Free] = css.seat_free,
    _a[ArmeniaSeatColors.Expensive] = css.seat_expensive,
    _a[ArmeniaSeatColors.CheckinPaid] = css.seat_checkin,
    _a[ArmeniaSeatColors.Price1] = css.seat_1,
    _a[ArmeniaSeatColors.Price2] = css.seat_2,
    _a[ArmeniaSeatColors.Price3] = css.seat_3,
    _a[ArmeniaSeatColors.Price4] = css.seat_4,
    _a);
var Seat = function (props) {
    var _a, _b;
    var colorsContext = React.useContext(SeatMapColorsContext);
    var color = props.isAvailable ? (_a = colorsContext === null || colorsContext === void 0 ? void 0 : colorsContext[props.segmentId]) === null || _a === void 0 ? void 0 : _a.get((_b = props.seat.price) === null || _b === void 0 ? void 0 : _b.amount) : null;
    return React.createElement(RootComponent, __assign({}, props, { className: colorsClass[color] }));
};
export default Seat;
