import { FareLockIcon } from './components/FareLockIcon/FareLockIcon';
import { Logo } from './theme/images/Images';
import FareGroupGridRulesButton from './components/FareGroupGridRulesButton/FareGroupGridRulesButton';
import FlightInfo from './components/FlightInfo/FlightInfo';
import SearchFormDatePicker from './components/SearchFormDatePicker/SearchFormDatePicker';
import SeatMap from './components/SeatMap/SeatMap';
import Seat from './components/SeatMap/Seat/Seat';
import SeatMapAdditionalDisclaimer from './components/SeatMapAdditionalDisclaimer/SeatMapAdditionalDisclaimer';
import QuickSearchForm from './components/QuickSearchForm/QuickSearchForm';
import FastSearch from './components/FastSearch/FastSearch';
import CheckoutServicesV2 from '@websky/core/src/Checkout/components/Checkout/ServicesV2/ServicesV2';
export const renders = {
    RenderFareLockIcon: () => FareLockIcon,
    renderFlightInfo: FlightInfo,
    MetasearchLoaderLogo: Logo,
    FareGroupGridRulesButton,
    SearchFormDatePicker,
    SeatMapService: SeatMap,
    SeatMapSeat: Seat,
    seatsInfoAdditionalSlot: SeatMapAdditionalDisclaimer,
    QuickSearchForm,
    FastSearch,
    CheckoutServices: CheckoutServicesV2
};
