import { __assign, __read, __rest } from "tslib";
import * as React from 'react';
import { useCallback, useMemo, useState } from 'react';
import cn from 'classnames';
import Collapse from '@material-ui/core/Collapse';
import MediaQuery from 'react-responsive';
import { useTheme } from '../../theme';
import Summary from './Summary/Summary';
import SearchForm from '../../SearchForm';
import DummySummary from './DummySummary/DummySummary';
import { initI18n, MOBILE_MIN_WIDTH, TABLET_MIN_WIDTH } from '../../utils';
import PromoCode from '../../PromoCode';
import { PromoCodeStatus } from '../../PromoCode/types';
import QuickSearchFormPromoCode from './QuickSearchFormPromoCode/QuickSearchFormPromoCode';
import { usePromoCode } from '../../PromoCode/hooks';
import { useConfig } from '../../context';
import { OverrideComponent } from '../../renderProps';
import { convertSearchParameters } from '../../Results/utils';
import { useDispatch } from 'react-redux';
import { clearPromoCode } from '../../SearchForm/store/promoCode/actions';
initI18n('QuickSearchForm');
var QuickSearchForm = function (_a) {
    var _b, _c;
    var _d;
    var parameters = _a.parameters, _e = _a.SearchFormProps, SearchFormProps = _e === void 0 ? { onSubmit: undefined } : _e, _f = _a.isDisabled, isDisabled = _f === void 0 ? false : _f, children = _a.children, hidePromoCode = _a.hidePromoCode, isPromoCodeApplied = _a.isPromoCodeApplied, isFlightChosen = _a.isFlightChosen;
    var onSubmit = SearchFormProps.onSubmit, restProps = __rest(SearchFormProps, ["onSubmit"]);
    var css = useTheme('QuickSearchForm').QuickSearchForm;
    var _g = __read(useState(false), 2), isOpen = _g[0], setIsOpen = _g[1];
    var _h = usePromoCode(), isPromoCodeFormOpen = _h.isFormOpen, setFormOpen = _h.setFormOpen, closeForm = _h.closeForm;
    var allowPromoCodes = useConfig().global.allowPromoCodes;
    var toggle = function () {
        setIsOpen(!isOpen);
    };
    var dispatch = useDispatch();
    var handleClearPromocode = useCallback(function () {
        dispatch(clearPromoCode());
        handleSearchStart(convertSearchParameters(parameters, ''));
    }, [isPromoCodeApplied]);
    var handleOpenPromoCode = useCallback(function () {
        setIsOpen(true);
        setFormOpen(true);
    }, [isPromoCodeFormOpen, isPromoCodeApplied]);
    var handleClosePromoCode = useCallback(function () {
        setIsOpen(false);
        setFormOpen(false);
    }, [isPromoCodeFormOpen, isPromoCodeApplied]);
    var handleSearchStart = function (params) {
        if (onSubmit) {
            onSubmit(params);
        }
        setIsOpen(false);
    };
    React.useEffect(function () {
        setIsOpen(false);
        setFormOpen(false);
    }, [isFlightChosen]);
    var promoCodeStub = useMemo(function () { return (React.createElement(PromoCode, { className: css.promoCode, status: isPromoCodeApplied
            ? PromoCodeStatus.Applied
            : parameters.promotionCode
                ? PromoCodeStatus.NotApplied
                : PromoCodeStatus.Empty, promoCode: 'any promocode', isFormOpen: false, isLoading: false, onAddPromoCode: function () { return null; }, onOpenForm: handleOpenPromoCode, onCloseForm: closeForm, onClear: handleClearPromocode })); }, [isPromoCodeApplied]);
    var renderSearchFormPromoCode = useCallback(function () {
        if (hidePromoCode) {
            return null;
        }
        return (React.createElement(QuickSearchFormPromoCode, { status: isPromoCodeApplied
                ? PromoCodeStatus.Applied
                : parameters.promotionCode
                    ? PromoCodeStatus.NotApplied
                    : PromoCodeStatus.Empty, promoCode: parameters.promotionCode, onClose: handleClosePromoCode, onOpen: handleOpenPromoCode, isFormOpen: isPromoCodeFormOpen, onSubmit: handleSearchStart, isPromoCodeApplied: isPromoCodeApplied }));
    }, [hidePromoCode, isPromoCodeFormOpen, isPromoCodeApplied]);
    return (React.createElement("div", { className: css.outer },
        React.createElement("div", { className: css.wrapper },
            React.createElement("div", { className: css.inner },
                isDisabled && React.createElement(DummySummary, null),
                !isDisabled && ((_d = parameters.segments) === null || _d === void 0 ? void 0 : _d.length) > 0 && (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: cn(css.outer__summary, (_b = {}, _b[css.outer__summary_open] = isOpen, _b)) },
                        React.createElement("div", { className: css.inner__summary },
                            React.createElement(Summary, { parameters: parameters, onClick: toggle, isOpen: isOpen, className: css.summary, renderPromoCode: function () { return (React.createElement(MediaQuery, { maxWidth: TABLET_MIN_WIDTH, minWidth: MOBILE_MIN_WIDTH }, !isOpen && !hidePromoCode && promoCodeStub)); } }),
                            React.createElement(MediaQuery, { minWidth: +TABLET_MIN_WIDTH + 1 }, !isOpen && !hidePromoCode && promoCodeStub))),
                    React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH },
                        React.createElement(Collapse, { mountOnEnter: true, unmountOnExit: true, in: isOpen },
                            React.createElement("div", { className: css.form },
                                React.createElement(OverrideComponent, { componentProps: __assign({ className: css.form__wrapper, renderPromoCode: renderSearchFormPromoCode, defaultParameters: parameters, onSubmit: handleSearchStart }, restProps), component: {
                                        SearchForm: SearchForm
                                    } })))),
                    React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
                        allowPromoCodes && (React.createElement(Collapse, { in: !isOpen, unmountOnExit: true, mountOnEnter: true }, !hidePromoCode && React.createElement("div", { className: css.promoCode__wrp }, promoCodeStub))),
                        React.createElement(Collapse, { mountOnEnter: true, unmountOnExit: true, in: isOpen },
                            React.createElement("div", { className: css.form },
                                React.createElement(OverrideComponent, { componentProps: __assign({ renderPromoCode: renderSearchFormPromoCode, defaultParameters: parameters, onSubmit: handleSearchStart }, restProps), component: {
                                        SearchForm: SearchForm
                                    } }))))))),
            React.createElement(Collapse, { in: !isOpen },
                React.createElement("div", { className: cn((_c = {},
                        _c[css.children_hidden] = isOpen,
                        _c)) }, children)))));
};
export default QuickSearchForm;
